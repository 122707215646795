import React, { useEffect } from 'react';
import styles from './Layout.module.scss';

import { Outlet, useLocation } from 'react-router-dom';
// import Footer from '../../components/Footer/Footer';

import logo from '../../assets/icon/logoZdan.png';

const Layout = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });
    }, [pathname])

    return (
        <>
            <header className={styles.header}>
                <div>
                    <a href='https://www.zdan.com.cn/' target='_blank' rel="noreferrer">
                        <img src={logo} alt="Логотип" />
                    </a>
                </div>
            </header>
            <Outlet />
            {/* <Footer /> */}
        </>
    )
}

export default Layout