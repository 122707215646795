export const ELEMENTS_DATA = {
    H: {
        name: '氢',
        translate_name: 'Hydrogenium',
        atomic_mass: '1,00794',
        serial_number: '001',
        color: '#E0358B',
        text: '氢不仅是门捷列夫化学元素周期表的第一个元素，也是宇宙中最轻的（比空气轻14.5倍）和最常见的元素。氢气是在16世纪就被发现了，但直到1783年才得到充分的研究。在正常情况下，这种气体只与活性金属和唯一的非金属——氟发生反应。 \n\n氢的作用是巨大的：它是水的组成部分，可用于合成氨，用于石油产品的加氢处理，也用于不饱和有机化合物的氢化。氢以生成方式多种多样：活性金属与水和酸的相互作用，碱性水溶液的电解。然而，最有效的制氢方法是在1000 °C下甲烷与水蒸气的转化。虽然氢及其化合物已被用于各个领域，但如果没有一种氢化合物——水，地球上的生命将无法存在，因此，展台中用它来展示氢元素。 \n\n最常见的含氢矿物是冰，人类一年中的几个月都在冰的陪伴下度过。很难想象，有一种宝石含有高达20%的水，它叫贵蛋白石。蛋白石中的水包含在微小的无定形硅凝胶球中，蛋白石正是由这些小球组成，没有它，蛋白石会变得模糊和开裂。 ',
    },
    He: {
        name: '氦',
        translate_name: 'Helium',
        atomic_mass: '4,002602',
        serial_number: '002',
        color: '#EF3A37',
        text: '氦是一种无色、无味、无嗅的气体，是整个门捷列夫化学元素周期表中反应性最小的元素。氦形成的所有化合物几乎都存在于巨大的压力下（大约一百万个大气压），或在放电作用下以气相状态存在。但稳定的内嵌富勒烯 He@C60是个有趣的例外，它是一个封闭在富勒烯碳框架中的氦原子，且氦原子未与碳框架发生化学结合。 \n\n虽然氦始终存在于地球大气中，但它最初是在太阳上被发现的。直到 28 年后，它才被确认为空气的成分之一。 \n\n氦气被广泛应用于工业领域，但在日常生活中也能找到它。比如能轻易升空的氦气球，还有气体放电灯。 \n\n通过深度冷却和进一步提纯天然氦气可以获得纯氦。 ',
    },
    Li: {
        name: '锂',
        translate_name: 'Lithium',
        atomic_mass: '6,941',
        serial_number: '003',
        color: '#A6A5A5',
        text: '作为碱金属族的创始成员，锂的化学性质与其他碱金属截然不同。碱金属被储存在煤油中，以防止与空气中的氧气发生反应，但锂作为密度最低的金属（0.533 g/m3），可以漂浮在煤油表面。因此，锂几乎是唯一一种被储存在凡士林下的金属。 \n\n很难想象如果没有锂，现代技术会如何发展，因为它是锂离子电池的一部分，而这类电池是手机、相机、笔记本电脑和其他设备中最常用的类型。在这类电源中，锂以钴酸盐、磷酸铁盐和镍盐的形式存在。锂离子电池有许多不可否认的优点：结构紧凑、能量容量高、无需维护。 \n\n金属锂通常通过电解盐熔体或从氧化物中还原来获得。 \n\n这里展示的锂辉石LiAl[Si2O6]是一种锂和铝的硅酸盐，是锂的主要矿石之一，也是一种美丽的宝石。锂辉石的绿色晶体称为隐晶石，淡紫色晶体称为坤石。它们形成于具有特定成分的花岗岩中，分布在世界各地，但最著名的矿床位于巴西、马达加斯加和阿富汗。 ',
    },
    Be: {
        name: '铍',
        translate_name: 'Beryllium',
        atomic_mass: '9,012182',
        serial_number: '004',
        color: '#3CB2E0',
        text: 'X 射线医生和品味高雅的富婆有什么共同点？没错，就是铍！前者在工作中会遇到它：因为铍对 X 射线的吸收率很低，所以它被用来制作 X 射线管的 "窗口"。后者把它戴在手指上：含有铬或钒杂质的铍铝硅酸盐就是著名的祖母绿宝石。 \n\n一般来说，这种金属在日常生活中很少使用——昂贵的含铍音响系统，还有特殊的铍青铜，可用于制造具有特殊用途的工具——这种合金在撞击时不会产生火花！铍青铜的其他应用领域与普通人相去甚远，比如航空航天工程、核能工程、激光材料和火箭燃料制造。门捷列夫化学元素周期表中展示的是带有圆形铍 "窗口 "的 X 射线管碎片。 \n\n在工业中，铍主要有两种制造方法：用镁还原氟化铍，对氯化铍和钠混合物的熔体进行电解。 \n\n铍的最重要来源之一是矿物绿柱石 Al2Be3[Si6O18]。它的透明晶体颜色各异，被视为宝石。您面前的是蓝色绿柱石，它的名字来源于它的颜色，叫做海蓝宝。它可能没有绿色绿柱石（祖母绿）昂贵，但也非常美丽！  ',
    },
    B: {
        name: '硼',
        translate_name: 'Borum',
        atomic_mass: '005',
        serial_number: '10,811',
        color: '#90C021',
        text: '当 J. Gay-Lussac 和 L. Thénard 于 1808 年首次获得硼时，他们很难想象 ，200 年后硼的化合物会被公认为人类已知最坚硬材料的纪录保持者。例如，纳米结构的立方氮化硼和纤锌矿型氮化硼的硬度接近金刚石。虽然这两种材料都是硼和氮的化合物——氮化硼，但都无法直接获得：元素硼是一种相当惰性的物质，只有在非常高的温度下才会与氮和其他元素发生作用。 \n\n不过，结晶硼的硬度非常高：在单质物质中，它的硬度仅次于金刚石。但它很少以纯硼的形式使用，在工业和日常生活中硼化合物更为常见。例如，硼硅玻璃被广泛用于制造光纤——玻璃丝通过电磁辐射传输信息：从洲际高速公路到家用电脑网络。 \n\n在工业中，硼是通过将硼砂提炼成 B2O3，然后用镁进行热还原而得到的。 \n\n电气石是最美丽的硼矿物之一(又称托玛琳石、碧玺)，它是一种成分复杂的硼硅酸盐。它的晶体具有很强的压电和热电特性，能完美地偏振光，彩色的透明晶体可用于珠宝首饰。  ',
    },
    C: {
        name: '碳',
        translate_name: 'Carboneum',
        atomic_mass: '12,0107',
        serial_number: '006',
        color: '#E0358B',
        text: '碳自古以来就为人们所熟知：它被用于金属冶炼，而我们的祖先自史前时代起就把火中的烟灰用作油漆。要分离纯碳并不容易，直到1791年，通过煅烧白垩上方的磷蒸气才得以实现。在常温下，碳是惰性的非金属，但加热时它表现出强烈的还原性能。 \n\n碳有一系列的同素异性体——最著名的当属金刚石和石墨，以及富勒烯的发现，这些富勒烯是类似于足球的纳米级凸闭多面体，于1996年获得诺贝尔化学奖。 \n\n我们遇到金刚石的次数比我们想象的要多得多：它是人们所知道的最坚硬的物质，因而它被用于钻井——金刚石能够在最坚硬的岩石上切割，在日常生活中它被用于玻璃切割机和其他切割工具。 \n\n碳在自然界中很常见，一般来说以化合物的形式更常见，但最常见的是方解石，碳酸钙CaCO3。这种矿物由石灰石、大理石等岩石构成，最小的方解石晶体是贝壳。 ',
    },
    N: {
        name: '氮',
        translate_name: 'Nitrogenium',
        atomic_mass: '14,0067',
        serial_number: '007',
        color: '#E0358B',
        text: '虽然氮气在我们周围无处不在——约占空气的78%，但直到1772年才被发现。同时，也很难确切确定谁是从空气中分离出氮气的第一人：好几位科学家同时对此进行了独立研究，包括G. Cavendish、J. Priestley、D. Rutherford和C. Scheele。后来发现，他们得到的气体非常稳定，化学惰性很强。在正常条件下，氮只能与锂反应，加热时几乎不会衰变成原子：N2的可见解离需要大约5000 K的温度。 \n\n在工业上，纯氮是由液体空气分馏产生的。 \n\n虽然纯氮是一种惰性气体，但它的许多化合物都是可燃的，甚至具有爆炸危险！烟火中使用不同的氮化合物，其中最常见的是硝酸铵。它不仅是肥料，也是火药的重要成分，可以在弹药、烟花爆竹中找到。 \n\n虽然氮气通常被称为"有机"化学，但在矿物的"无机"世界中也有氮化合物——氯化铵NH4Cl！在自然界中，它是在极端的条件下形成的：在火山喷气孔周围，或者举个例，在燃烧的石炭层中。 ',
    },
    O: {
        name: '氧',
        translate_name: 'Oxigenium',
        atomic_mass: '15,9994',
        serial_number: '008',
        color: '#E0358B',
        text: '氧气是真正的生命元素，没有它，不仅人类，地球上几乎所有生物都无法生存！这不仅仅是呼吸的问题，因为氧气约占人体细胞质量的 65%。在自然界中，氧气以两种截然不同的气态分子形式存在：一种是提供呼吸作用的双原子氧气，另一种是毒性极强的三原子臭氧。 \n\n氧是仅次于氟的最活跃的非金属。它可与除 He、Ne 和 Ar 以外的所有已知元素形成氧化物，与一些碱金属形成过氧化物和超氧化物。 \n\n除呼吸作用外，氧气还被用作治疗缺氧的药物，在食品工业中被用作推进剂，在化学工业中被用作通用氧化剂。氧气还可用于生产气凝胶，这是一种重量极轻的材料，具有高强度、耐化学性和高隔热性。 \n\n氧气是地壳中含量最高的元素，占地壳总质量的 49%多一点，它是大量矿物的组成部分。水晶是一种无色透明的石英，是氧化硅，这种矿物一半以上（53.26%）是由氧构成的！ ',
    },
    F: {
        name: '氟',
        translate_name: 'Fluorum',
        atomic_mass: '18,9984032',
        serial_number: '009',
        color: '#ED6B2E',
        text: '氟是整个门捷列夫化学元素周期表中最活跃的非金属。相对于其他元素和化合物，它的行为就像一个真正的侵略者：在氟气氛中，甚至水和铂都能燃烧！因此毫不奇怪，游离形式的氟在发现76年后才得以分离出来：由于它具有很强的氧化特性，几乎不可能从化合物中提取。 \n\n自相矛盾的是，我们在厨房里最常见的氟化合物是氢氟碳化合物，氟化烃用作制冷设备的制冷剂，聚四氟乙烯用作不粘锅的涂层。 \n\n在工业上，氟是通过在KF熔体中电解无水HF溶液产生的。 \n\n自然界中的氟化合物并不多，其中一种是氟盐, NaF。而它的猩红色晶体仅在富含氟和碱的特定成分的岩石中形成。世界上为数不多的这样的地方之一是在俄罗斯，在科拉半岛的基比尼地区，这个样本来自那里！ ',
    },
    Ne: {
        name: '氖',
        translate_name: 'Neon',
        atomic_mass: '20,1797',
        serial_number: '010',
        color: '#EF3A37',
        text: '氖是宇宙中含量排名第五的元素。然而，它在 1898 年才被发现，当时在液化空气蒸汽的发射光谱中观察到了以前未知的红色和橙色光谱线。这种非金属的电子外壳是填充的，因此至今尚未发现氖的价态化合物——因此它被归类为惰性气体。不过，基于现代分析方法，已经确定存在单电荷离子 Ne+。 \n\n氖最广为人知的用途当然是广告牌：人们谈论霓虹灯广告不是没有道理的——充满氖和氮气混合物的管子在通过放电时会发出特有的橘红色光芒。不过，氖还被用于激光技术和气体放电灯，可能很多人都见过带有漂亮灯盘的手表——其中的氖围绕着做成数字形状的电极发光。 \n\n地球上氖最集中的地方是大气层，但在地壳中也有少量存在。氖可通过空气的液化和分离过程来获得。低温精馏法可以生产纯度高达 99.9999% 的氖! ',
    },
    Na: {
        name: '钠',
        translate_name: 'Natrium',
        atomic_mass: '22,98976928',
        serial_number: '011',
        color: '#A6A5A5',
        text: '游离状态下的钠是一种极其活跃的金属，会与空气中的氧气和水发生反应，甚至在与人体皮肤接触时也会被点燃（因为皮肤上有汗迹）。虽然人类自古以来就知道钠的化合物，但由于钠的基本活性，直到 1807 年发现电解法后才将其分离出来。后来发现钠也可以通过化学方法获得：例如，通过锆热法或用煤高温还原碳酸钠。 \n\n在日常生活中几乎不可能找到金属钠。不过，这种金属以离子的形式在我们身边随处可见：我们食用氯化钠（大家都知道的食盐）和谷氨酸钠，用氢氧化钠清洁管道，用碳酸氢钠治疗胃灼热。 \n\n在工业中，通过电解氢氧化物或氯化物可以得到金属钠。 \n\n在矿物中钠也很常见！钠-钙拉长石是最美丽的含钠矿物之一。它的名字来源于它的发现地——加拿大的拉布拉多省，而它的美丽则归功于物理：拉长石由最薄的纯钙钠长石片组成，它们非常薄，变成了体积干涉光栅，在光线下闪烁出不同的颜色！ ',
    },
    Mg: {
        name: '镁',
        translate_name: 'Magnesium',
        atomic_mass: '24,3050',
        serial_number: '012',
        color: '#3CB2E0',
        text: '镁的发现和分离历史长达 100 年：人们从矿泉水中获取镁，用煤还原氧化镁，通过电解从氯化物熔体中分离镁，但官方认为镁是 1792 年通过电解从氧化镁和氧化汞的混合物中获取的。在空气中加热时，镁会燃烧出耀眼的白色火焰，不仅形成氧化物，还形成氮化物。镁的一个显著特点是它能够在二氧化碳气流中燃烧，而二氧化碳并不支持燃烧。 \n\n尽管大量的镁被用于制造轻质高强合金和耐火材料——许多镁化合物的熔点都非常高——但我们仍然可以找到由镁制成的火镰。由于镁 "火柴 "能够在异常高的温度下燃烧，而且不会受潮，因此至今仍深受游客和旅行者的喜爱。 \n\n镁的工业生产方法是电解镁、钙和钾氯化物的混合物熔体。 \n\n天然氢氧化镁矿物水镁石在自然界中十分罕见。正是这种矿物在退火后变成了氧化镁，可用作耐火材料！这种矿物通常形成于地球表面或富含镁的岩石风化过程中的浅层。 ',
    },
    Al: {
        name: '铝',
        translate_name: 'Aluminium',
        atomic_mass: '26,9815386',
        serial_number: '013',
        color: '#ECACCE',
        text: '铝是地壳中含量第三高的元素，也是含量最高的金属，约占 8%。在正常情况下，铝表面覆盖着一层非常坚固的氧化膜，保护铝不被氧化。由于这种独特的性质，铝不具有腐蚀性，被广泛应用于工业领域。 \n\n铝合金不仅是一种名副其实的有翼金属——由于其轻质和强度，铝合金已在航空领域取代木材，成为飞机制造的主要材料，而且水合氧化铝（铝胶）在医学上可用作抗酸剂、镇痛剂和包封剂。 \n\n铝是通过电解冰晶石中的氧化铝熔体获得的。 \n\n这种美丽的蓝色晶体是蓝晶石，即硅酸铝 Al2[SiO4]O。这种矿物被认为是一种很有前途的铝矿——俄罗斯没有大量的铝土矿储量，而铝通常是从铝土矿中提取的，但俄罗斯却有大量的蓝晶石矿藏！目前，科学家们正在努力开发技术，以便能够将其用作矿石。 ',
    },
    Si: {
        name: '硅',
        translate_name: 'Silicium',
        atomic_mass: '28,0855',
        serial_number: '014',
        color: '#90C021',
        text: '硅是地壳中含量最丰富的元素之一，确切地说，仅次于氧，占地壳质量的 27%。它是一种典型的半金属，在颜色和光泽上与金属相似，但非常脆。如果不是上世纪中叶开始研究硅的半导体特性，它很难得到广泛应用。 \n\n我们现在生活在一个真正的硅时代！大约 70 年前，硅晶体管首次问世。从那时起，经过了漫长的岁月，硅在电子工业中不仅没有被淘汰，反而成为了第一大元素！每年生产数百亿晶体管......供地球上的每个人使用！硅还是无数电子设备，特别是处理器、合金和耐热建筑材料的基础。 \n\n最常见的硅矿物和地球上最常见的矿物之一是石英——氧化硅，它是地壳中两种最常见元素的化合物。石英有许多种颜色，其颜色是由一些微量杂质造成的。这里展示的黄色品种被称为黄水晶。',
    },
    P: {
        name: '磷',
        translate_name: 'Phosphorus',
        atomic_mass: '30,973762',
        serial_number: '015',
        color: '#E0358B',
        text: '磷于 1669 年被汉堡的一位炼金术士发现，当时他正在寻找哲人之石和无尽的黄金之源。磷有四种同素异形体，它们的物理和化学性质截然不同：白磷、红磷、黑磷和金属磷。例如，白磷在室温下就能在空气中自燃，并发出可见光，而红磷在这种条件下是化学惰性的。 \n\n磷的使用是基于其基本的生物重要性：几乎所有生产的正磷酸都用于生产各种磷肥。如果没有磷肥，就根本不可能年年丰收——播种几次谷物或蔬菜后，土壤就会枯竭。因此，磷才是真正的肥力元素！ \n\n这里介绍的矿物——磷灰石——与我们的关系非常非常密切。我们的牙釉质由磷酸钙 Ca5(PO4)3(F,OH,Cl)的微小晶体组成！这种矿物的大矿藏是磷及其化合物的来源——由磷灰石和磷酸盐与二氧化硅烧结而成。 ',
    },
    S: {
        name: '硫',
        translate_name: 'Sulfur',
        atomic_mass: '32,065',
        serial_number: '016',
        color: '#E0358B',
        text: '虽然硫在门捷列夫化学元素周期表中位于氧下方，但它的物理和化学性质却与氧截然不同。与较轻的同类物质不同，硫是一种黄色固体，加热时容易聚合成链和环状分子。我们无法确切知道硫是何时被发现的，因为在我公元前，人们就已经开始使用天然硫了。 \n\n大约一半的硫用于生产硫酸，也就是汽车电池中的电解质。在日常生活中，我们也能找到硫元素：它是制作火柴头的成分之一。 \n\n在工业规模上，大部分硫是从含硫矿石中提取的。不过，也有一些更奇特的方法来提取这种非金属：例如，在印度尼西亚，硫按照传统在伊珍活火山上开采，因为火山使得硫从地球内部升起。 \n\n硫是大多数有色金属矿石的组成部分，形成硫化物——金属和硫的化合物。这里展示的黄铜矿是一种铁和铜的硫化物 CuFeS2。它的表面覆盖着一层薄薄的氧化膜，因此不像通常那样呈现金黄色，而是蓝紫色！',
    },
    Cl: {
        name: '氯',
        translate_name: 'Chlorum',
        atomic_mass: '35,453',
        serial_number: '017',
        color: '#ED6B2E',
        text: '纯氯是一种黄绿色气体，具有刺激性的窒息气味。氯是最常见的卤素，在自然界中以两种稳定的同位素形式存在：氯-35 和氯-37。它是最强的氧化剂之一，几乎能与所有简单物质反应，生成氯化物。 \n\n氯被用于生产漂白剂和杀虫剂，也是人们熟悉的食盐的一部分，还被用于生产各种聚合物，如聚氯乙烯（PVC）。聚氯乙烯是一种极其重要的材料，水管用它制成——它重量轻、无腐蚀，因此非常耐用！ \n\n生产氯的低效化学方法已被电化学方法所取代，这两种方法都是基于电流对食盐水溶液的作用。 \n\n当然，氯也是岩盐的一部分，但更罕见、更有趣的是矿物硫铝石 Na6(SO4)2FCl！这种矿物是在天然盐水干涸时形成的，因此它几乎吸收了能从溶液中提取的一切元素：硫、氟，当然还有氯！ ',
    },
    Ar: {
        name: '氩',
        translate_name: 'Argon',
        atomic_mass: '39,948',
        serial_number: '018',
        color: '#EF3A37',
        text: '即使伟大的科学家也会有粗心大意的时候，惰性气体氩就是一个很好的例子。氩是地球大气中含量第三高的元素（仅次于氮和氧），但化学家直到 1892 年才确定氩存在于地球大气中。这是因为氩的反应活性非常低：目前，人们只知道这种元素的两种价态化合物——HArF 和 CU(Ar)O——而且这两种化合物都只在接近液氦温度时才稳定。虽然氩形成了相对稳定的包合物（例如 Ar*6H2O），但它们不能被称为经典意义上的 "化合物"，因为其中的氩原子是主化合物晶格中的 "客人"，不会与主化合物形成化学键。 \n\n如果您家的窗户是塑料的，请透过窗户看一看。你会看到什么？没错，就是氩气！在现代双层玻璃窗中，氩气经常被注入塑料窗玻璃之间，因为氩气可以提高玻璃窗的隔热性能。现在，如果你有灯泡，看看它们。你明白我的意思了吧？又是氩气，不过在这种情况下，氩气的用途是防止钨丝氧化并以WO3形式挥发。 \n\n在工业条件下，氩气是通过分馏从空气中提取氮气和氧气的副产品。',
    },
    K: {
        name: '钾',
        translate_name: 'Kalium',
        atomic_mass: '39,0983',
        serial_number: '019',
        color: '#A6A5A5',
        text: '钾是一种非常轻且易熔的银色金属，在自然界中只存在于与其他元素的化合物中。当把钾及其盐类引入燃烧器的火焰中时，火焰会呈现出明亮的紫色。钾化合物的使用已有数百年的历史：碳酸钾从 11 世纪起就被用作洗涤剂。 \n\n钾化合物几乎随处可见。氢氧化钾是每个人在学校化学课上接触过的碱，广泛用于制药、冶金和炼油等行业。钾盐可用作压电体和镇静剂，也为园艺家所熟知，氯化钾是农业的重要肥料。 \n\n钾可通过与钠的热化学还原法、氯化钾与铝的熔融还原法以及与其他碱金属一样的电解法获得。 \n\n在矿物领域，钾的作用相当重要。例如，它是许多长石的成分，亚马逊石就是其中之一。亚马逊石是非常美丽的绿色矿石，其颜色的本质至今是个谜！据说这是由于其成分中含有极少量的铅。 ',
    },
    Ca: {
        name: '钙',
        translate_name: 'Calcium',
        atomic_mass: '40,078',
        serial_number: '020',
        color: '#3CB2E0',
        text: '钙是地壳中含量排名第五的元素，是我们骨骼和岩溶洞穴中钟乳石的基础，甚至是合成新的超重化学元素的重要原料。纯钙是一种活性金属，能缓慢地从空气中吸收氧气、水分和二氧化碳。由于它的高活性，它和其他碱土元素一样，最初是通过电解法生产的。日常生活中几乎不可能找到金属钙，但它在工业中的应用却很广泛：它可用于稀土元素的冶金热还原，也可用于去除钢铁中的溶解氧。在日常生活中，钙通常以碳酸盐的形式使用：例如，它被用来制作最常见的粉笔。 \n\n在工业中，金属钙是通过电解其盐类熔体以及铝热还原氧化钙而获得的。 \n\n石膏不是最常见的钙矿物，但它也是自然界中最有趣的钙矿物之一。用于固定手臂或腿部骨折处的白色粉末的近亲是硫酸钙水溶液（CaSO4x2H2O）。在沙漠中，石膏晶体以一种奇异的方式聚集在一起，形成像盛开的玫瑰花一样的花簇，这就是它们的第二个名字 "沙漠玫瑰 "的由来。 ',
    },
    Sc: {
        name: '钪',
        translate_name: 'Scandium',
        atomic_mass: '44,955912',
        serial_number: '021',
        color: '#cfcfcf',
        text: '钪是一种稀土元素，是铝的化学类似物。这种金属极为分散，在地壳中的含量仅为每吨 10 克。钪会被酸-氧化剂钝化，加热到赤热时会与典型的非金属发生反应，其氢氧化物具有两性特性。 \n\n氧化钪可用于生产照明设备——因为它能产生非常接近太阳辐射的辐射。此外，钪最近还被积极地用作铝合金的合金添加剂：生产出的合金非常坚固和硬度高，可用于造船、汽车制造和桥梁建设等领域。 \n\n由于钪不形成独立矿藏，它是在加工铝、钨、钛和其他一些矿石时作为副产品提取的。 \n\n虽然有独立的钪矿物，但钪作为杂质存在于其他矿物中更为常见。萤石 CaF2 就是其中之一。不要对其化学式中没有钪感到惊讶——某些矿床中的萤石以杂质的形式存在，氧化钪的含量不到1%！ ',
    },
    Ti: {
        name: '钛',
        translate_name: 'Titanium',
        atomic_mass: '47,867',
        serial_number: '022',
        color: '#cfcfcf',
        text: '尽管传统观点认为水会加速金属的腐蚀，但钛的情况并非如此。在水环境中，钛被一层薄薄的氧化膜所覆盖，这层氧化膜可以保护钛免受许多腐蚀性试剂的侵蚀：硝酸、稀 H2SO4、氯水等。如果这层薄膜被去除（例如在熔化过程中），钛的化学活性就会急剧增加。 \n\n钛的主要应用领域是冶金。它被用来制造许多不同的合金，通常具有轻质高强度的特点。其中包括著名的镍钛诺合金——一种钛和镍的合金，它能 "记住 "自己的形状，并在变形后经加热恢复形状。钛还广泛应用于医疗领域，因为其产品不会被人体体液破坏。例如，它可用于制造固定牙冠的根桩冠。 \n\n在工业条件下，钛可通过镁热还原 TiCl4 得到的。基于在氧化钙和氯化钙混合物熔体中氧化物直接连续还原钛的电化学方法也越来越受欢迎。 \n\n最常见的钛矿物之一是钛铁矿ilmenite，一种铁和钛的氧化物/FeTiO3。它的名称是为了纪念南乌拉尔地区的伊尔门山脉（它首次被发现的地方）。后来的事实证明，这种矿物在地球上许多地方都有发现，并经常被用作钛矿石。 ',
    },
    V: {
        name: '钒',
        translate_name: 'Vanadium',
        atomic_mass: '50,9415',
        serial_number: '023',
        color: '#cfcfcf',
        text: '钒能形成许多颜色美丽的化合物，因此它以斯堪的纳维亚美丽女神芙蕾雅（Vanadis）的名字命名。钒具有化学惰性，不会与水、酸和碱溶液发生反应，但会与氧气形成四种不同成分的氧化物：VO、V2O3、VO2 和 V2O5。 \n\n钒化合物可用于电子、能源和硫酸生产——五氧化二钒是二氧化硫氧化成硫酸酐的催化剂。由于强度高，钒被用于汽车工业和石油开采（钒钢被用于制造钻井平台），钒和铬的合金被用于铸造价格低廉但强度惊人的扳手，这种扳手被标为 CrV。 \n\n钒从铁矿石中通过热还原氧化钒，以及在氢对氯化钒的作用下获得。 \n\n钒铅矿——钒酸铅 Pb5[VO4]3Cl ——是一种极其美丽的矿物，可惜十分罕见。要形成这种矿物，就必须在自然界的一个地方同时存在富含铅和钒的岩石和矿石，在它们的共同氧化过程中形成钒铁矿。',
    },
    Cr: {
        name: '铬',
        translate_name: 'Chromium',
        atomic_mass: '51,9961',
        serial_number: '024',
        color: '#cfcfcf',
        text: '铬化合物的化学性质多种多样：氧化度为 +2 时，它是一种极强的还原剂（在水溶液中可将 H2O 还原成 H2）；氧化度为 +6 时，它是一种氧化剂（可从相应卤化氢的浓溶液中释放出 Cl2、Br2 和/I2）。它对人体的影响也大不相同：氧化程度低时，它是参与脂类、蛋白质和碳水化合物新陈代谢的重要元素；氧化程度高时，它是一种危险的基因毒性致癌物质。同时，不同氧化程度的化合物也具有不同的颜色，这就是铬名称的由来：希腊语 χρῶμα 的意思是 "颜色"。 \n\n金属铬作为不锈钢和合金的一种成分被广泛应用于日常生活中。其中值得一提的是尼赫罗姆镍铬合金——一种镍和铬的合金，在氧化气氛中具有很高的耐热性（最高可达 1250℃）。因此，镍铬丝被广泛用于制造加热元件（如烙铁或炊具）。 \n\n在工业中，通过铝热还原 Cr2O3 以及电解 CrO3 的硫酸水溶液可以获得金属铬。 \n\n如果黄铜矿——PbCrO4不是最常见的铬矿物，那么就是最美丽的铬矿物，它明亮的颜色是由于成分中含有铬。这种矿物是俄罗斯发现的第一种新矿物（1766 年）。所展示的样品就是在这种矿物的发现地开采的：乌拉尔中部的别列佐夫矿床。 ',
    },
    Mn: {
        name: '锰',
        translate_name: 'Manganum',
        atomic_mass: '54,938045',
        serial_number: '025',
        color: '#cfcfcf',
        text: '虽然锰只是地球上含量第 14 高的化学元素，但它却承担着极其重要的冶金任务：由于锰与氧和硫这些非金属的亲和力很高，所以锰被用来去除钢中的氧和硫。锰存在于所有动植物的机体中。这种元素参与神经递质的合成——神经递质是帮助神经元之间传递信号的物质，并有益于 B、C 和 E 族维生素的吸收。 \n\n氧化程度较高的锰化合物作为一种强力有效的氧化剂，被广泛应用于化学领域。锰化合物的另一个用途是锰锌电池，即勒克朗谢电池，它是一种主要的化学电源，几乎无处不在：为钟表、玩具、手电筒、遥控器等提供动力！ \n\n我们展示的是锰的代表矿物是蔷薇辉石Rhodonite（Mn, Ca）5[Si5O15]。它的名字反映了它的颜色——"rhodon "意为 "粉红色"。这种矿物经常被用作观赏石和珠宝首饰。 ',
    },
    Fe: {
        name: '铁',
        translate_name: 'Ferrum',
        atomic_mass: '55,845',
        serial_number: '026',
        color: '#cfcfcf',
        text: '几千年前，从矿石中冶炼铁的方法的发现确实改变了人类的生活：它的产品具有很高的强度，并且在许多特性上大大超过了当时发现的所有金属和合金。但很少有人知道铁化合物——二茂铁夹心配合物，也就是Fe2+离子，位于两个环戊二烯阴离子之间——在上个世纪再次发生了革命性变化：这次是在金属有机化学中，它的研究人员获得了诺贝尔奖。一个铁离子一次与十个碳原子结合，这些键非常稳定：二茂铁加热至470℃时不分解，不与热浓盐酸和碱溶液相互作用。 \n\n铁常用于工业和日常生活，约占全部冶金产量的95%。最着名的铁器无疑是最常见的钢钉。虽然它在潮湿的环境中容易腐蚀，但具有高强度和耐磨性。 \n\n在工业上，生产铁最常用的是高炉工艺：在2000℃温度下从氧化石中还原铁。 \n\n如果我们的大气中没有氧气，也许我们会看到更多天然的铁。然而，我们不时地也会遇到它本身的形式，因为它来自太空！这里展示的是铁陨石Campo del Cielo的碎片。这颗陨石和其他铁陨石一样，是由某种成分的铁镍合金组成的——有了它，我们可以清楚地看到，我们面前是陨石，而不是人造材料。 ',
    },
    Co: {
        name: '钴',
        translate_name: 'Cobaltum',
        atomic_mass: '58,933195',
        serial_number: '027',
        color: '#cfcfcf',
        text: '人们自古以来就知道钴的化合物。因此，在图坦卡蒙墓中发现了许多蓝色钴玻璃碎片，著名的 Gzhel 绘画也使用了钴盐。值得注意的是，钴能够像许多贵金属一样溶解氢，而不会与氢形成化合物。在人体内，钴是维生素 B12 的一部分，参与造血和酶促反应。 \n\n钴主要用于制造耐化学性合金、催化剂和陶瓷。当氧化铝与钴盐一起煅烧时，可以得到钴蓝，这是一种用作水彩画和油画颜料的亮蓝色化合物。 \n\n钴可以通过火法冶金和湿法冶金两种方法获得，前者是将镍矿石溶解在硫酸或氨水中。 \n\n即使是少量的钴也能使矿物变得异常美丽——在钴钙钛矿（Ca,Co)CO3 中，只有百分之几的钴就足以让纯方解石的白色晶体变成你在展台上看到的五颜六色的花状晶体！ ',
    },
    Ni: {
        name: '镍',
        translate_name: 'Niccolum',
        atomic_mass: '58,6934',
        serial_number: '028',
        color: '#cfcfcf',
        text: '"镍 "是镍的名字，在矿工语言中是一个脏话，用来形容两面人。这种金属被如此命名并非偶然：它的矿物 红砷镍矿（镍砷化物 NiAs）外表酷似铜矿石，但从其中获取铜的所有尝试显然都不成功。直到 1751 年，瑞典矿物学家 A. Kronstedt 才从镍矿中分离出一种性质类似铁的新元素。 \n\n在日常生活中，我们都会直接接触到镍，或者说是镍的铜合金，因为这种物质经常被用来铸造小面额的金属硬币。与铁不同，铜镍合金硬币不会生锈，但随着时间的推移，硬币表面会覆盖一层薄薄的氧化物，颜色也会变深。 \n\n在工业条件下，镍主要通过三种方式生产，其中最有趣的是铜镍锍与一氧化碳的相互作用。这种方法会产生[Ni(CO)4]的挥发性络合物，热分解后会产生纯金属镍。 \n\n暗镍蛇纹石是水性镍硅酸盐和镍镁硅酸盐的混合物，呈亮绿色。它是在原生镍矿石氧化过程中形成的，因此是寻找镍矿床时的一个重要指标。如果您发现了暗镍蛇纹石，千万不要错过！',
    },
    Cu: {
        name: '铜',
        translate_name: 'Cuprum',
        atomic_mass: '63,546',
        serial_number: '029',
        color: '#cfcfcf',
        text: '几乎所有金属都有一个显著特征，那就是呈银白色，但铜却不是这样，它呈金粉色。在古代，铜被用来制造劳动和生活用具，它与锡的合金——青铜——被用来冶炼各种武器。金属铜不会与水和稀酸发生反应，在潮湿的空气中，铜会氧化形成碱式碳酸铜（II）——孔雀石。 \n\n由于铜具有极高的导热性，因此被用于散热装置，包括空调、制冷和供暖器。最重要的是，铜是一种优良的导体！铜的低电阻率是其在电气工程中用于电力电缆和电线以及音频电缆的基础。 \n\n获取铜的方法多种多样：硫化铜的氧化焙烧、用氢气或氨气还原氧化铜、电解铜盐水溶液。 \n\n铜最美丽的矿物是其碳酸盐岩、天青石和孔雀石。这里展示的是蓝铜矿（在中国古称石青），它被文艺复兴时期的艺术家用作群青的廉价替代品，而群青是从另一种蓝色矿物青金石中提取的。 ',
    },
    Zn: {
        name: '锌',
        translate_name: 'Zincum',
        atomic_mass: '65,409',
        serial_number: '030',
        color: '#cfcfcf',
        text: '锌是金属形成两性氧化物和氢氧化物的典型例子：ZnO 和/Zn(OH)2 很容易与酸和碱发生作用，生成阳离子或阴离子中含有锌的相应盐类。一个有趣的事实是，纯锌几乎不与强酸发生反应，但添加少量其他金属就会大大提高其反应性。在极少数情况下，锌的氧化度为 +1，形成簇 [Zn2]2+，，它是 Zn2Cl2 和 (η5-C5Me5)2Zn2（以 Zn-Zn为中心 的夹心配合物）等化合物的组成部分。 \n\n锌化合物在日常生活中非常常见：例如，硫化锌是最常用的无机荧光粉之一，碲化锌、硒化锌和磷化锌也被广泛用作半导体材料。金属锌可用于保护其他金属（主要是铁）免受腐蚀；例如，它可用于保护铁螺栓和螺母。 \n\n金属锌有一个非常有趣的特性——沸点很低（906℃）。这也是获取这种金属的最古老工业方法的基础：氧化锌 ZnO 与煤或焦炭一起煅烧，然后捕获产生的锌蒸汽。不过，工业上生产锌的主要方法是电解其硫酸盐溶液。 \n\n这里展示的就是硫化锌，即闪锌矿矿石。由于其成分中含有杂质铁，所以颜色很深。这种矿物具有很强的光泽，使其在其他有色金属矿石中脱颖而出。 ',
    },
    Ga: {
        name: '镓',
        translate_name: 'Gallium',
        atomic_mass: '69,723',
        serial_number: '031',
        color: '#ECACCE',
        text: '镓是门捷列夫化学元素周期表中的第二个为纪念法国而命名（Gallia是其拉丁名）的元素。不过，据传该元素的发现者勒考克-德-博博德朗（Lecoq de Boabodran）在命名时有双重含义：勒考克（Lecoq）这个名字在法语中的意思与拉丁语gallus（公鸡）的意思相同（博博德朗本人否认了这一点）。镓最有趣的特性之一是熔点很低，只有29.8℃，因此用手触摸可以熔化它。 \n\n开采出的镓约有 97% 用于生产半导体化合物。例如，氮化镓、锑化物、磷化物和砷化物被广泛用于制造半导体激光器、蓝色发光二极管和紫外线发光二极管。当电流沿正向通过这些材料时，其中的电荷载体（电子和空穴）会重新结合，在光谱的某个狭窄区域发射光子。 \n\n由于镓的扩散性很强，本身不会形成矿石和矿物，因此在工业中，镓通常是在加工多金属和铝矿时作为副产品获得的。这里展示的是铝矿石（铝土矿），它含有镓杂质。',
    },
    Ge: {
        name: '锗',
        translate_name: 'Germanium',
        atomic_mass: '72,64',
        serial_number: '032',
        color: '#90C021',
        text: '锗可以说是一种反常的材料：它具有反常的热容量曲线，而且与大多数已知物质和元素不同，它在从液态转变为固态的过程中会膨胀。对于许多侵蚀性介质，锗具有很强的惰性：锗耐水、耐空气、耐酸碱溶液。不过，它也可以转化为可溶形式：例如，通过接触王水或碱性过氧化氢溶液。 \n\n一个有趣的事实是，直到20世纪30年代末，锗实际上还没有被用于工业。不过，锗随后被用于各种半导体器件，例如晶体管，其发明者于1956年获得诺贝尔奖。现在，在这些设备中，锗几乎已被硅完全取代，但在音乐行业，锗晶体管放大器仍因其能够为音乐带来独特的音效而备受推崇。 \n\n在工业上，锗的制造方法如下：在600℃的高温下用二氧化氢还原，然后通过分区熔化提纯。 \n\n锗是一种稀有元素，在自然界中非常分散。它很少形成独立的矿物，更多的时候是从含锗浓度较高的矿物中提取出来的。例如，在某些矿床中，磁铁矿FeFe2O4（您在这里看到的晶体）本身就能积聚少量锗元素——虽然含量太少，无法进入矿物化学式，但足以提取锗元素。 ',
    },
    As: {
        name: '砷',
        translate_name: 'Arsenicum',
        atomic_mass: '74,92160',
        serial_number: '033',
        color: '#90C021',
        text: '砷是门捷列夫化学元素周期表中典型的半金属。灰色，有光泽，类似金属，但非常脆，而且众所周知，有剧毒！这就决定了砷在很长一段时间内的主要用途——毒药。直到二十世纪，人们才发现砷化合物的半导体特性，并对其进行了详细研究，此后，砷化合物的微小晶体随处可见。 \n\n砷化镓是砷和镓的化合物，是一种几乎到处都在使用的半导体。它被用来制造二极管、场效应晶体管、激光器、太阳能电池和其他现代电子设备。 \n\n我们不能忘记砷在医学中的应用。尽管毒性极高，但砷化合物洒尔佛散salvarsan是最早的化疗药物之一。上世纪初，这种药物开创了治疗传染病，尤其是梅毒的新纪元。虽然现在它已被更有效、更安全的类似物所取代，但其他有机砷化合物仍然是治疗某些传染病不可或缺的药物。 \n\n砷矿物中最美丽的一种是其硫化物雌黄 As2S3。从它的名字就可以知道，它曾被用作黄色油漆的颜料，被称为"皇家黄"。现在，雌黄和另一种硫化砷——雄黄一起被用作生产砷的矿石。 ',
    },
    Se: {
        name: '硒',
        translate_name: 'Selenium',
        atomic_mass: '78,96',
        serial_number: '034',
        color: '#E0358B',
        text: '硒是人体内30多种重要生物活性化合物的组成成分：缺硒会导致碘吸收障碍，并导致免疫系统功能失调。另一方面，硒过量会对中枢神经系统、肝脏和肾脏造成严重损害。从化学角度看，硒是一种典型的非金属和硫的类似物，能与氧和其他氧化剂发生反应，形成多种含氧酸。 \n\n由于硒的半导体特性，其化合物和合金被用于光电子和热电子领域。例如，世界上第一个光敏电阻器就是在硒化合物的基础上制造出来的。有了光敏电阻，就可以在没有电流放大器的情况下制造出简单可靠的光电继电器。 \n\n硒通过硫酸酐从二氧化硫中提取的。 \n\n独立的硒矿物非常罕见，但由于硒与硫非常相似，因此经常在硫化物中发现硒，尤其是在硫化铅方铅矿PbS中。不过，有时硒的含量非常高，以至于完全不含硫，这就形成了硒化铅矿物——硒铅矿。 ',
    },
    Br: {
        name: '溴',
        translate_name: 'Bromum',
        atomic_mass: '79,904',
        serial_number: '035',
        color: '#ED6B2E',
        text: '溴是门街列夫化学元素周期表中两种（另一种是汞）在标准状态下呈液态的化学元素之一（从形式上看，它们还可以包括钫，但其熔点尚未确定）。它是一种侵蚀性极强的物质，甚至能溶解黄金，只有钽和铂能抵御它的影响。有趣的是，溴最早是由一位不知名的药剂师安托万-杰罗姆-巴拉尔（Antoine Jerome Balar）发现的，查尔斯-杰勒德（Charles Gerard）后来写道，不是巴拉尔发现了溴，而是溴发现了巴拉尔。 \n\n由于溴的化学活性很高，日常生活中并不使用纯溴，但其化合物经常被用作药物。例如抗焦虑药芬纳西泮以及镇静剂可尔瓦乐和瓦洛科金。 \n\n溴几乎没有独立的矿物质——它们非常稀少，颗粒也非常小。大量的溴储量集中在海水、天然卤水和岩盐中，主要由矿物海绿石组成，通过氯气作用于海绿石，然后用水蒸气将溴从溶液中吹出来，就可以从中获得溴。 ',
    },
    Kr: {
        name: '氪',
        translate_name: 'Krypton',
        atomic_mass: '83,798',
        serial_number: '036',
        color: '#EF3A37',
        text: '氪是一种无色惰性气体，是在分析空气时利用光谱研究技术发现的。每1000L空气中大约含有1mL氪。地壳中的铀矿石和钍矿石不断地使大气中的氪富集，这是长寿命同位素铀和钍自发原子裂变的结果。 \n\n在正常情况下，氪不会发生任何化学反应，但它能够与水、苯酚和丙酮形成包合物。2003年，人们首次获得了氪的有机化合物HKrC≡CH。 \n\n氪可从空气分离过程中获得，目前已实现工业化生产，可应用于生产大功率准分子激光器、白炽灯，以及用作电动火箭发动机的工质。 ',
    },
    Rb: {
        name: '铷',
        translate_name: 'Rubidium',
        atomic_mass: '85,4678',
        serial_number: '037',
        color: '#A6A5A5',
        text: '铷和其他碱金属一样，具有最强的还原性。它在空气中几乎瞬间点燃，并与水发生爆炸反应，生成氢氧化铷RbOH，这是一种极强的碱。此外，天然铷是一种弱β发射体，因为它有27.8%是由放射性同位素87Rb组成。 \n\n铷在日程生活中很难找到，但它的一些化合物（如氯化物）在医学中被用作精神病患者的情绪稳定剂。虽然人们对铷的生物学作用还不是很清楚，但已经证实缺乏铷会导致精神失常。铷和铯一起被用于超精密时间测量装置——所谓的"原子钟"。在这些装置中，1秒相当于一个铷原子发生一定次数共振的时间间隔。 \n\n在工业条件下，最大数量的铷是作为锂生产的副产品获得的。纯铷是通过电解法提取的。 \n\n铷很少存在于矿物中，但大量的铷集中在云母中，尤其是锂云母KLi2Al（Al,Si）3O10（F,OH）2中，铷在其中作为杂质存在。这种云母形成于富含锂的花岗伟晶岩中，其中不仅富含锂，还富含许多其他稀有和分散元素。 ',
    },
    Sr: {
        name: '锶',
        translate_name: 'Strontium',
        atomic_mass: '87,62',
        serial_number: '038',
        color: '#3CB2E0',
        text: '锶是一种碱土金属，由于其高活性，不会以游离态存在。纯锶与空气成分的反应速度非常快，会覆盖一层由氧化锶、过氧化物和氮化物组成的黄色薄膜。细微分散的锶具有自燃性——能在空气中自燃。 \n\n锶的应用范围非常广泛：从冶金、烟火到核医学、磁性材料的生产。锶盐（铝酸锶）可用作荧光粉，一种能将吸收的能量转化为光辐射的物质。荧光粉可用于荧光灯、医药，以及辐射指示器的制造。 \n\n锶是通过铝热法获得的，即用金属铝还原氧化锶。 \n\n锶在自然界中最常见的形式是矿物天青石——硫酸锶SrSO4。这种矿物因其特有的淡蓝色而得名：拉丁文 caelestis 的意思是 "天空"。 ',
    },
    Y: {
        name: '钇',
        translate_name: 'Yttrium',
        atomic_mass: '88,90585',
        serial_number: '039',
        color: '#cfcfcf',
        text: '虽然钇被归类为稀土元素，但它的克拉克值约为 26g每吨：是前 30 种最常见的化学元素之一。钇是一种相当活泼的金属，但在空气中加热至750℃时，钇是稳定的：在约400℃时，钇上会形成一层致密的Y2O3氧化物薄膜，保护钇不被进一步氧化。此外，钇对以铀和钚为基础的液态核燃料具有极强的惰性，这也是用钇制造核燃料运输管道的原因。 \n\n钇被广泛应用于各种高科技产业：生产热电材料、超导体、红外陶瓷等。钇铝石榴石（YAG）和掺杂钕、铒或其他稀土元素的铝酸钇被用作医疗和美容领域固体激光器的工质。钇的另一个特殊用途是稳定立方氧化锆。这种化合物的晶体被称为菲阿尼特单晶体，是最便宜、最好的钻石仿制品。但为了使锆原子和氧原子保持在晶格中的位置，必须加入少量的钙离子或钇离子。 \n\n在工业中，钇通过萃取和离子交换从与其他稀土元素的混合物中分离出来，然后通过用锂或钙还原无水卤化物将其转化为元素状态Y。 \n\n这里展示的是钇含量最高的矿物之一，磷钆矿YPO4。这种罕见的磷酸盐是在花岗伟晶岩中形成的，当分散元素在熔体中浓缩时，通常岩石中的钇浓度太低，无法形成矿物颗粒。不过，尽管这种晶体非常小，但肉眼可见！ ',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
    },
    Zr: {
        name: '锆',
        translate_name: 'Zirconium',
        atomic_mass: '91,224',
        serial_number: '040',
        color: '#cfcfcf',
        text: '金属锆早在1824年就被发现，但直到100年后才分离出不含杂质的锆！锆耐腐蚀，在正常情况下不与酸、碱发生反应，完全没有生物价值。此外，这种金属的化合物在摄入人体后具有生物惰性，对人体无害。 \n\n由于锆具有很高的生物惰性，因此在医学上被用于生产各种假肢和手术器械。锆的抗腐蚀性促使人们制造出薄的金属锆涂层。二氧化锆可用于生产硬质陶瓷，用于制造陶瓷刀。这种刀具具有很强的耐腐蚀性，而且长期不需要磨刀。 \n\n自然界中最常见的锆矿物是锆石 ZrSiO4。锆石存在于各种火成岩中，但很少形成大晶体，通常是几毫米大小的颗粒。然而，正是它们让我们能够确定火成岩的年龄！锆石会积累铀——只有一点点，占其成分的百分之一到千分之一。随着时间的推移，这些铀逐渐衰变，其衰变产物残留在锆石中，而现代分析方法甚至可以测定这些微小颗粒中残留的铀量和由此产生的铅，并计算出锆石的形成年代（以及形成锆石的岩石）！ ',
    },
    Nb: {
        name: '铌',
        translate_name: 'Niobium',
        atomic_mass: '92,90638',
        serial_number: '041',
        color: '#cfcfcf',
        text: '金属铌具有化学惰性，与其"邻居"钽非常相似。它们都不溶于大多数无机酸（包括硝酸、盐酸、稀硫酸和正磷酸），只对苛刻的碱性物质和复合酸混合物（HF + HNO3）敏感。因此，铌和钽在很长一段时间内（约42年前）被认为是同一种物质。当它们被证明是不同的化学元素后，铌被重新命名，以纪念古希腊国王坦塔卢斯的女儿尼奥贝。 \n\n铌的主要应用之一是生产超导材料。例如，大型强子对撞机中超导磁体的绕组就是由1200吨铌钛合金制成的——这种合金也用于制造现代核磁共振断层成像仪的磁体。在日常生活中，电解电容器中也能发现铌的身影：多孔海绵铌作为阳极，氧化铌作为电介质。 \n\n在工业条件下，金属铌可通过用铝或碳还原Nb2O5以及钠热还原K2[NbF7]获得。 \n\n这里展示的是铌星叶石K2NaFe7(Nb,Ti)2Si8O26(OH)4(F,O)。这是一种稀有矿物，是最美丽的铌矿物之一。它的深棕色带橙色色调的片状晶体存在于富含碱的火成岩中，例如在科拉半岛的基比尼山（Khibiny Mountains）发现的火成岩。 ',
    },
    Mo: {
        name: '钼',
        translate_name: 'Molybdaenum',
        atomic_mass: '95,94',
        serial_number: '042',
        color: '#cfcfcf',
        text: '钼是一种难熔金属，它有一个有趣的特点：在某些条件下，它能够形成钼酸——一种不以自由酸形式分离的且含有7 个钼原子的盐。这种盐在与弱酸作用时会形成聚合阴离子——多钼酸盐。钼是许多酶的组成部分，尤其负责调节动物体内的尿酸代谢。 \n\n钼可用作合金添加剂，以提高合金的耐腐蚀性和耐热性，其质量为93和99的同位素可用作同位素指示剂。硫化钼的粒度在 1 - 100μm之间，是一种干润滑剂，具有出色的耐高温性能。 \n\n所有的钼生产方法都可归结为生产三氧化钼并用氢进一步还原。 \n\n钼的主要矿石是其硫化物，称为辉钼矿MoS2。它的灰色片状晶体像金属一样闪闪发光，但非常柔软，具有延展性。它很容易在纸上留下痕迹，就像铅笔上的石墨一样，毕竟它们的晶格有很多相似之处。 ',
    },
    Tc: {
        name: '锝',
        translate_name: 'Technetium',
        atomic_mass: '98,9063',
        serial_number: '043',
        color: '#cfcfcf',
        text: '尽管人们普遍认为所有已知的放射性元素都位于门捷列夫化学元素周期表的"底部"，但有两种元素却例外，其中之一就是锝（另一种是钷）。虽然锝可以在地壳中找到，但它在地壳中的总含量只有大约18000吨，因此锝在元素周期表中的位置在很长一段时间内都是空的。直到1937年，人们才通过用氘核轰击钼靶合成了锝，这是第一种人工合成的化学元素。 \n\n从化学角度看，锝及其化合物的毒性很低，但其放射性却对人类构成威胁。尽管如此，亚稳同位素99mTc 仍被广泛应用于医学领域的少量放射性核素诊断，这也是锝的主要用途。 \n\n虽然锝存在于自然界，但无法从矿物中提取，因为锝的含量太低。因此，锝是人工生产的，通过化学方法从放射性废物中提取。上文提到的重要异构体99mTc 的半衰期很短（6.04 小时），因此医疗中心可直接从β放射性的99Mo中提取，并立即用于研究。 ',
    },
    Ru: {
        name: '钌',
        translate_name: 'Ruthenium',
        atomic_mass: '101,07',
        serial_number: '044',
        color: '#cfcfcf',
        text: '贵金属钌是为了纪念俄罗斯而命名的，它有一个独特的特性——不仅绝对不溶于纯浓酸，也不溶于王水。钌还有另外两个特点，能够与所有可能的正氧化度形成化合物，另外，能形成 Ru-C 键的钌有机化合物。 \n\n二氧化钌和钌酸铋可用于厚膜电阻器。这两种化合物的合成约占钌产量的 50%。这种电阻器非常常见，价格也相当便宜。 \n\n钌是提炼铂时的副产品，也可从核裂变碎片中获得。 \n\n钌偶尔会以原生形态出现在自然界中，但更常见的是与铱和锇（铂族的其他金属）形成合金，另一种常见的矿物是硫钌矿——硫化钌 RuS2。在展示的样品中，我们看不到钌矿物，因为其粒度只有十分之一或百分之一毫米！这展示的是由黄铁矿和黄铜矿组成的诺里尔斯克矿石样本中，其中含有硫化钌晶粒。 ',
    },
    Rh: {
        name: '铑',
        translate_name: 'Rhodium',
        atomic_mass: '102,90550',
        serial_number: '045',
        color: '#cfcfcf',
        text: '铑这一名称来自古希腊语ῥόδον，意为"玫瑰"：许多铑化合物呈深红色。然而，要获得这些化合物非常困难，因为铑具有优异的化学惰性，甚至超过了铂。通常情况下，要使铑溶解，需要使用过氧化钠或过氧化钡等碱性氧化剂的熔体。 \n\n日程生活中与铑最常见的接触是购买和佩戴银饰和白金首饰：由于铑具有极强的耐化学腐蚀性，薄薄地涂一层铑在首饰上，可以防止褪色。此外，铑合金还被用作催化剂（包括废气中和器-过滤器）。 \n\n由于铑的化学惰性及其与其他贵金属相似的化学性质，铑的工业生产十分困难。目前，正在开发一种溶解和分离贵金属的新方法，该方法是用活性氟化剂——四氟溴酸钾（III）（KBrF4）对基质进行高温氧化，然后将铑和其他金属转化为氯化络合物。 \n\n铑是一种稳定存在于诺里尔斯克矿石中的金属，尽管数量很少。这就是原生铑及其与其他铂族金属、硫化物和砷化物的天然合金。只有在电子显微镜下才能看到这些矿物的最小颗粒，肉眼无法看到它们。 ',
    },
    Pd: {
        name: '钯',
        translate_name: 'Palladium',
        atomic_mass: '106,42',
        serial_number: '046',
        color: '#cfcfcf',
        text: '钯是铂金属中反应性最强的一种：它会与浓硫酸、浓硝酸和王水发生反应。这种金属在空气中很稳定，但在高温下长时间加热后会形成氧化钯（II）。在氢能汽车发展的时代，钯具有非常重要的价值，它能主动吸收氢气——1体积的钯能吸收多达900体积的氢气。 \n\n由于其化学惰性，钯及其合金被用于制造医疗器械和心脏起搏器部件。钯还广泛用于首饰：金钯合金（白金）和装饰性银钯合金。钯是一种贵金属，在交易所和场外市场都有交易。 \n\n小型金属钯是通过电沉积生产的，而精细结晶钯则是通过盐溶液还原生产的。 \n\n钯在自然界中以大量化合物的形式存在：主要是硫化物、砷化物、碲化物以及与其他金属的各种合金。在诺里尔斯克矿石中发现了天然钯、六方铋钯矿Pd(Bi,Te)、斜方锡钯矿Pd2Sn和许多其他提取钯的矿物。它们的颗粒通常非常小，肉眼无法看到，但它们确实存在！ ',
    },
    Ag: {
        name: '银',
        translate_name: 'Argentum',
        atomic_mass: '107,8682',
        serial_number: '047',
        color: '#cfcfcf',
        text: '人类自古以来就知道银，并一直被用作杀菌剂。然而，这种使用可能非常危险，因为银的抑菌特性在一定浓度下也会对人体产生毒性作用。身体长期摄入银会导致银质沉淀症，表现为银沉积在皮肤和粘膜上，导致它们的颜色不可逆转地变为灰色（由于银化合物的光解分解和游离金属的释放）。在已知的病例中，有位病人的所有皮肤都变了颜色，尽管他本人仍然活着。 \n\n银在医学上最有名的应用是所谓的“硝酸银棒”，由硝酸银和硝酸钾组成。它被用来烧灼伤口和小疣，硝酸银具有很强的杀菌和收敛作用。除医药外，银还用于电气工程，因为它具有最高的导电性和导热性（在所有金属中最高），即使在非常高的温度下也具有很强的抗氧化性。 \n\n大约80%的银是在加工含有Ag2S 的复杂重金属硫化物时作为副产品获得的。专有银矿石的加工方法是在有氧气的情况下用氰化钾浸出银，然后用锌或铝沉淀金属。 \n\n自然界中的银通常以其本身的形式存在，但很容易形成化合物。这些化合物通常是硫化物，但偶尔也会发现银和锑的化合物——锑银矿（dyskrasite /Ag3Sb）。这里展示的这种稀有矿物晶体产自捷克共和国的普日布拉姆（Příbram）。',
    },
    Cd: {
        name: '镉',
        translate_name: 'Cadmium',
        atomic_mass: '112,411',
        serial_number: '048',
        color: '#cfcfcf',
        text: '镉是一种毒性极强的金属，能与含硫酶和氨基酸结合。不过，健康人体内仍存在少量镉，因为镉是合成苯甲酰甘氨酸所必需的，并有助于其他微量元素的吸收。作为一种中等活性的金属，镉会与非氧化性酸、氧和其他非金属发生反应。 \n\n镉化合物可用于生产颜料和示温涂料，也可用于制造镉电极。镉化合物最常用于制造红色和黄色颜料。因此，镉红是硫化镉和硒化镉的混合物，而镉黄则是纯硫化镉。这些颜料的溶解度低，耐热性非常高。 \n\n镉是锌矿加工过程中产生的副产品。这种矿石是硫化锌，即闪锌矿ZnS。镉会取代硫化物中的部分锌原子，如果镉含量丰富，甚至会形成一种独立的硫化物，这种硫化物被称为绿帘石CdS。 ',
    },
    In: {
        name: '铟',
        translate_name: 'Indium',
        atomic_mass: '114,818',
        serial_number: '049',
        color: '#ECACCE',
        text: '铟是一种轻熔、非常柔软的金属，是在分析闪锌矿时，在发射光谱中观察到一条新的明亮蓝线而发现的。这种金属就是根据这条蓝线的颜色——靛蓝而命名的。铟与氧和硫的亲合性相当高，加热时会形成二元化合物。铟具有严格的碱性，即使煮沸也不会与浓碱溶液发生反应。 \n\n这种金属可用于制造铟锡薄膜电极，用于生产显示器和电视机的液晶屏幕，也是合成多种荧光粉的必要材料，其同位素还可用于放射物理研究。 \n\n铟是从锌、镉和锡的废品中通过铝或锌溶液的胶结作用获得的。 \n\n由于铟很少形成独立的矿物——铟在地壳中的平均含量仅为0.25g/t，因此铟是作为多金属矿的副产品被开采出来的。这些矿石主要由铅、锌和铁的硫化物（方铅矿PbS和闪锌矿ZnS）组成。主要矿石矿物是闪锌矿，铟就是在这种矿物中首次被发现的。 ',
    },
    Sn: {
        name: '锡',
        translate_name: 'Stannum',
        atomic_mass: '118,710',
        serial_number: '050',
        color: '#ECACCE',
        text: '锡是一种神奇的金属，其本质是多晶体的——能够以几种不同的形态存在，具有不同的物理性质。当冷却到13°C以下时，所谓的白锡（β-变质）会变成灰锡（α-变质），并碎成粉末。斯科特的南极探险队遇到了类似现象——储存在锡槽中的所有燃料丢失了，在低温条件下，锡相发生转变并碎裂...... \n\n锡被用作各种性质合金的耐腐蚀涂层，用于生产食品容器（马口铁）。由于锡的熔点较低，纯锡及其与铅的合金可用作有效而廉价的焊料。锡焊料的抗拉强度可达100Mpa！ \n\n锡是在熔炉中用煤煅烧二氧化锡得到的。 \n\n从古至今，锡的主要矿石矿物是其氧化物——锡石SnO2。锡石的晶体呈棕黑色，非常坚硬（几乎比所有普通矿物都硬），而且很重，锡在晶体中的含量刚刚超过其质量的3/4！这些晶体来自哈巴罗夫斯克边疆区的梅列克河，那里有大量的锡矿藏。 ',
    },
    Tl: {
        name: '铊',
        translate_name: 'Thallium',
        atomic_mass: '204,3833',
        serial_number: '081',
        color: '#ECACCE',
        text: '铊是化学元素世界中的一个奇葩。纯铊是一种银白色的重金属，性质与铅相似。然而，氢氧化铊 1（TlOH）的性质与 KOH 相似，是一种典型的碱，能溶解于水并将 pH 值调至碱性。在与有氧存在的醇类发生反应时，它也表现得像碱金属：例如，铊在接触乙醇时会转化为相应的乙酸盐。 \n\n由于铊的毒性极高，其用途非常有限。溴化铊和碘化铊被广泛用于红外光学，而铜酸铊被认为是一种高温超导体。经铊活化的碘化钠晶体可用于闪烁剂量计来记录伽马射线。 \n\n在工业中，铊从硫化物矿石中提取，其中微量的铊与铜、铅和锌伴生。金属铊通过电解法从其盐类中提取。 \n\n铊是近 50 种矿物的成分之一，但所有这些矿物都极为罕见，其提取量通常也非常小。因此，铊的主要矿石是硫化物，铊以杂质的形式存在于其中，并作为副产品被提取出来。展台上展示的是黄铁矿FeS2 是铊矿石之一，它被称为 "愚人金"。虽然黄铁矿不含金，但在某些矿床中，它含有足够可以提取的铊！ ',
    },
    Pb: {
        name: '铅',
        translate_name: 'Plumbum',
        atomic_mass: '207,2',
        serial_number: '082',
        color: '#ECACCE',
        text: '铅是人类最早学会熔化的金属：最早的铅制品可追溯到公元前 6400 年。铅的特点是熔点低、质地软：纯铅可以很容易地在普通厨房灶具上熔化，然后用刀切开，不过用铅调料制作铅沙拉是一个极其危险的想法，因为铅的毒性很高。如果用醋拌这样的 "沙拉"，会有甜味，因为生成的醋酸铅 (CH3COO)2Pb 有甜味。 \n\n由于以前人们对铅的毒性知之甚少，因此铅在家庭中的用途曾经很广泛。例如，古罗马生产和使用了大量的铅，甚至在格陵兰冰层中也发现了铅的浓度。幸运的是，现在铅的使用频率已经大大降低：例如，它被用来制造渔具的铅坠、子弹和 X 射线防护罩。 \n\n在工业中，金属铅是在矿炉中通过还原氧化铅生产出来的。 \n\n许多含铅的矿物色彩斑斓，这里展示的是磷氯铅矿/Pb5(PO4)3Cl 也不例外。这种美丽的矿物由铅矿氧化形成，主要条件是磷的存在，否则就会形成硫酸铅或碳酸铅。 ',
    },
    Bi: {
        name: '铋',
        translate_name: 'Bismuthum',
        atomic_mass: '208,98040',
        serial_number: '083',
        color: '#ECACCE',
        text: '铋是门捷列夫化学元素周期表中的一个不归点：在它之后的所有元素都没有稳定同位素。虽然以前认为铋本身是稳定的，但 2003 年发现它是一种弱α发射体，半衰期很长（即使以整个宇宙的标准来看）：(1.9±0.2)⋅1019 年。它不会对人类造成辐射危害，因为一克金属中每年只有约 100 个铋原子衰变。 \n\n铋的应用有趣而多样。例如，以铋为基础的一些合金——Wood 和 Rose——在沸水中很容易熔化，Bi2O3 氧化物被广泛用作粘合剂和防腐剂。女孩（和有女人味的男人）可以直接接触氧化铋-氯化铋和钒酸铋：前者用作粉饼、指甲油和其他化妆品的珠光添加剂，后者用作亮黄色化妆品的颜料。 \n\n在工业中，铋与铅一起开采，这影响了铋的价格：当铅的开采量增加时，铋的价格往往会下降。金属铋可通过与铁还原（Bi2S3）或与碳还原（Bi2O3）从矿石中获得。 \n\n最常见的情况是，铋和许多其他分布不均的元素一样，通常是作为其他金属矿石加工过程中的副产品被开采出来的。不过，铋也有自己的矿物，即硫化铋Bi2S3。这种矿物与硫化物类的许多代表矿物相似：灰色，有金属光泽，硬度不高，但比重很大：高达 7 g/cm3！ ',
    },
    Nh: {
        name: '鉨',
        translate_name: 'Nihonium',
        atomic_mass: '286',
        serial_number: '113',
        color: '#ECACCE',
        text: '化学元素周期表中的第 113 号元素是第一个在亚洲国家发现的元素。日本理化学研究所（RIKEN）的科学家们只合成了 3 个 113 号元素原子，而联合核研究所（Joint Institute for Nuclear Research）则合成了 10 个。尽管如此，日本科学家的优先权还是在命名中得到了认可，因为他们合成的轻同位素 "鉨"在衰变过程中转化成了备受研究的同位素。 \n\n鉨同位素是从镆同位素的α衰变以及镎和钙的核聚变中获得的。',
    },
    Fl: {
        name: '鈇',
        translate_name: 'Flerovium',
        atomic_mass: '289',
        serial_number: '114',
        color: '#ECACCE',
        text: '鈇是一种超重元素，于 1998 年在俄罗斯联邦杜布纳首次合成，并以弗勒罗夫核反应实验室命名。鈇的化学和物理性质受到相对论效应的强烈影响：例如，由于Fl-Fl 相互作用微弱，鈇可能是门捷列夫表中沸点最低的金属，沸点约为 140 ℃。它的化学性质大概类似于惰性气体，而不是铅。 \n\n尽管质量数为 286 的鈇同位素可用来生产另一种反式基团元素——鎶，但鈇实际上并无实际用途。它也仅用于研究目的。 \n\n从合成的角度来看，最有趣的同位素是 298Fl，因为它具有神奇的质子数 Z = 114。从理论上讲，它的半衰期可达数天甚至数年，这与其他超重元素不同：这就是所谓的 "稳定岛 "的表现。合成鈇-298 的一个可能反应是 204Hg 和 136Xe 的聚变，副产品是 40Ca。如果能够实现这一过程，而且生成的金属确实具有相对稳定性的特点，那么鈇的应用范围将大大扩展。 ',
    },
    Mc: {
        name: '镆',
        translate_name: 'Moscovium',
        atomic_mass: '290',
        serial_number: '115',
        color: '#ECACCE',
        text: '镆是第115种元素，以联合核研究所所在的莫斯科地区命名。镆是一种非过渡金属，是碱金属的类似物。从电子结构来看，镆的单价阳离子非常稳定。由于 7p2 电子对的稳定性，镆只表现出+1 和+3 的氧化度。 \n\n已知的镆同位素有三种，每一种都是由钙-48 作用于镅-243 而形成的。 ',
    },
    Lv: {
        name: '鉝',
        translate_name: 'Livermorium',
        atomic_mass: '293',
        serial_number: '116',
        color: '#ECACCE',
        text: '1999 年，美国伯克利的一家实验室首次报告发现了第 116 号元素，但后来发现这一说法是伪造的。因此，2000 年，俄罗斯杜布纳联合核研究所与美国利弗莫尔国家实验室合作，首次证实了鉝的合成。目前已知的所有 Lv 同位素的半衰期都在几毫秒左右，但其较重的同位素可能更稳定，因此被归入 "稳定岛"。在化学性质上，鉝与其他绿铝有很大不同：例如，在氧化度 +6 的情况下，它是科学界已知的最强的化学氧化剂之一。 \n\n鉝没有实际应用价值，但可以用来生产较轻的反式氧化物。 \n\n248Cm 和 48Ca 以及 245Cm 和 48Ca 的聚变反应可用于生产 Lv。 ',
    },
    Sb: {
        name: '锑',
        translate_name: 'Stibium',
        atomic_mass: '121,760',
        serial_number: '051',
        color: '#90C021',
        text: '锑是一种银白色的半金属，能与其他金属发生活性反应，形成锑化物、酸和卤素。人类自古以来就知道锑：早在公元前十九世纪，古埃及人就用天然硫化锑染黑眉毛。如今，锑对人体的毒性已被确定，它会损害皮肤和呼吸器官，但仍有许多锑化合物被用于医药中。 \n\n锑在半导体工业中用于生产二极管、红外探测器和霍尔效应装置。锑能增强其他金属，特别是锡和铅的强度。例如，在铅中加入锑可制成弹片和子弹的合金，在曳光弹中，锑化合物通常被用作在子弹飞行过程中燃烧的烟火剂的一部分。 \n\n获取锑的主要方法是焙烧硫化矿石，然后用煤还原氧化物。 \n\n最常见的锑矿物是它的硫化物——辉锑矿Sb2S3。辉锑矿通常是金矿床的伴生矿物，但有时也会形成独立的锑矿床，其针状晶体可长达50cm，有时甚至更长！ ',
    },
    Te: {
        name: '碲',
        translate_name: 'Tellurium',
        atomic_mass: '127,60',
        serial_number: '052',
        color: '#90C021',
        text: '碲元素以地球命名，是一种银白色的类金属。碲表面上与金属相似，但实际上却具有非金属的典型特性：与氢反应生成碲氢，与碱和浓酸反应。在所有放射性核素中，碲同位素128Te 的半衰期最长，是宇宙年龄的160万亿倍。 \n\n碲具有惊人的半导体特性，可用于生产超导体和窄频带半导体。碲的另一个应用是在橡胶硫化过程中的使用。很少有人想到，光盘之所以能够多次刻录（CD-RW、DVD-RW），是因为其表面有一层可变形的碲合金反射层。 \n\n碲的主要生产来源是铜和铅电解生产过程中产生的泥渣。 \n\n碲在自然界中的分布较少，但可形成大量化合物。可惜的是，正因为如此，矿物沉淀的尺寸通常非常小。这里展示的矿石样本含有矿物碲铅矿的颗粒。这些颗粒异常细小，肉眼无法看到，但通过对矿床矿石成分的研究，可以确认它们的存在。 ',
    },
    Po: {
        name: '钋',
        translate_name: 'Polonium',
        atomic_mass: '208,9824',
        serial_number: '084',
        color: '#90C021',
        text: '钋是一种软金属，没有稳定的同位素。钋是唯一能在低温下形成单原子简单立方晶格的化学元素。金属钋在空气中会迅速氧化，与卤素形成四卤化物，遇酸会形成粉红色的盐。 \n\n钋-210 的一个重要应用领域是为自主太空装置生产强大而体积小的热源。钋-210 可用于电离气体，在与铍和硼的合金中可用于生产体积小、功率强大的中子源。 \n\n钋-209 是用质子辐照铋-209 产生的，而钋-210 则是首先用中子辐照金属铋-209 产生铋-210，随后铋-210 发生β衰变产生的。 ',
    },
    I: {
        name: '碘',
        translate_name: 'Iodium',
        atomic_mass: '126,90447',
        serial_number: '053',
        color: '#ED6B2E',
        text: '单质碘是一种具有紫色金属光泽的结晶物质。碘是在硫酸与海藻灰盐水一起煮沸时发现的，煮沸时会释放出紫色蒸汽，冷却后会变成具有明亮光泽的深色晶体。碘大量存在于海藻和海带中，也以碘矿物质的形式存在。 \n\n5% 的碘酒精溶液可用于伤口周围皮肤的消毒。含碘的造影剂广泛用于各种放射学和断层扫描研究。放射性同位素碘-131在医学中用于诊断和治疗甲状腺疾病。 \n\n矿物碘的形态很少，它们很稀有，晶体通常也很小。虽然碘不是从矿物中提取的，而是从海藻中提取的，但我们可能会遇到一些碘矿物。例如，由碘和银AgI组成的化合物——碘银矿。这种矿物在世界上只有少数几个矿藏！ ',
    },
    At: {
        name: '砹',
        translate_name: 'Astatum',
        atomic_mass: '209,9871',
        serial_number: '085',
        color: '#ED6B2E',
        text: '砹是自然界中最稀有的化学元素：它在地壳中的总含量约为一克。因此，对砹化学性质的研究非常少，尽管已经确定它与卤素系列的其他代表元素有足够的不同：例如，在固态下，它形成具有金属晶格的晶体，而固体 I2 等则在晶格中含有双原子分子。在有氧化剂存在的酸性溶液中，砹呈水合单电荷阳离子形式，具有与典型金属 Tl+ 和 Cs+ 阳离子相似的一些特性。 \n\n目前，砹仅用于研究目的，但 211At 已被提议用于核医学，作为一种高活性α粒子源。 \n\n由于砹在自然界中的浓度极低（但它作为铀衰变链中的一种产物而存在和形成），只能通过用高能α粒子辐照铋来人工获取。即使是毫微克数量的砹也很难分离出来，因为砹的放射性极强，会熔化产生砹的铋靶。 ',
    },
    Ts: {
        name: '鿬',
        translate_name: 'Tennessine',
        atomic_mass: '294',
        serial_number: '117',
        color: '#ED6B2E',
        text: '鿬名义上属于卤素系列，位于砹之后。在室温下，鿬应该是固体，沸点约为 300 ℃。由于沸点太低，鿬与其他卤素不同，预计是单原子的。虽然鿬属于卤素，但由于从原子核上移除了大量电子，它绝对无法表现出卤素特有的氧化特性。预计鿬最稳定的氧化度为 +1。 \n\n铍-249 与钙-48 的反应被用来合成 117 元素，结果产生了 10 多个新元素核。 ',
    },
    Xe: {
        name: '氙',
        translate_name: 'Xenon',
        atomic_mass: '131,293',
        serial_number: '054',
        color: '#EF3A37',
        text: '惰性气体化学始于氙。1962年，人们发现氙与六氟化铂PtF6相互作用，形成六氟铂酸氙XePtF6和其他类似盐的产物——一种具有离子晶格的黄色粉末（即含有Xe+离子，或更确切地说是[XeF]+）。从那时起，人们又得到了许多其他相对稳定的氙化合物，包括各种氟化物、氧化物甚至酸——氙（H4XeO6）和氙（H2XeO4）。 \n\n在日常生活中，氙被用来填充灯泡和其他光源，并用作低毒性麻醉剂。此外，它还被用于航天技术：纯氙被用作航天器电喷气发动机的工质，氙氟化物被用作火箭燃料的氧化剂。 \n\n与其他惰性气体一样，氙也不是天然矿物。在工业中，它是将液态空气精馏分离成氧气和氮气的副产品。氪和氙留在液氧相中，然后通过硅胶吸附或蒸馏分离混合物。 ',
    },
    Rn: {
        name: '氡',
        translate_name: 'Radon',
        atomic_mass: '222,0176',
        serial_number: '086',
        color: '#EF3A37',
        text: '惰性气体氡因其自身的放射性会发出蓝色荧光，冷却后变成黄色。这种气体非常重，比空气重 7.6 倍！虽然氡属于惰性气体，但它并不具有特殊的化学惰性——它会与氧气、氟发生反应，并能进入各种笼化物。 \n\n氡在医学上主要用于制备氡浴液，用于治疗多种皮肤病，强化血管和毛细血管。 \n\n氡 222 是通过穿过镭 226气流 得到的。 \n\n自然界中氡的主要来源之一是花岗岩！无论多么令人惊讶，花岗岩的成分中都含有大量放射性矿物质。但这并不可怕——通常这只会导致辐射本底的轻微增加，就像在涅瓦河的花岗岩堤坝上或花岗岩装修的地铁站里看到的那样。花岗岩中积累的放射性元素在衰变时会形成氡，如果你不打算住在花岗岩洞穴中，就不必担心氡！ ',
    },
    Og: {
        name: '锘',
        translate_name: 'Oganesson',
        atomic_mass: '294',
        serial_number: '118',
        color: '#EF3A37',
        text: '鿫是迄今为止最后合成的化学元素之一。它是为了纪念苏联院士尤里-佐拉科维奇-奥加内斯扬而命名的，在他的领导下，许多超重元素在杜布纳被合成出来。尽管从形式上看，鿫属于惰性气体，但在标准条件下，它很可能具有固态聚集状态，而鎶和鈇（铅和汞的形式类似物）将会更加 "惰性"。因此，在电负性元素（如氟）的作用下，鿫将相对容易氧化，氧化度达到 +4，而相对于活性金属，它本身也将作为氧化剂，形成化合物 Og-。虽然目前这只是一种推测，但由于 鿫 的半衰期极短，目前还没有合成出任何化合物。 \n\n鿫只用于研究目的。 \n\n249Cf 和 48Ca 核的聚变反应被用来生产 Og。 ',
    },
    Cs: {
        name: '铯',
        translate_name: 'Cesium',
        atomic_mass: '132,9054519',
        serial_number: '055',
        color: '#A6A5A5',
        text: '铯是利用光谱分析发现的第一种元素。由于铯在室温下熔点极低，它处于半液态。铯是一种强还原剂，在空气中会瞬间闪烁，形成超氧化铯。由于铯具有惊人的反应性，这种金属必须储存在由特殊玻璃制成的密封安瓿瓶中，并置于氩气或氢气环境中，因为铯可以破坏普通的玻璃材质。 \n\n铯可用于电子、无线电和电气工程、医药和核能。锑化铯膜可用作在反射工况下极其高效的光电阴极。这种阴极可用于生产利用外部光效现象工作的光电倍增管。 \n\n这种金属是通过叠氮化铯分解矿石和用锆还原重铬酸盐获得的。 \n\n最常见的铯矿物之一是成分复杂的铝硅酸盐矿物——铯榴石/(Cs,Na)2(Al2Si4O12)x2H2O。它的名字是为了纪念狄俄斯库里神话中的孪生兄弟波鲁克斯 和卡斯托耳中的卡斯托耳——在地质学中，它的 "兄弟 "是以前被称为卡斯托里特的矿物透锂长石。这些矿物形成于富含锂的伟晶花岗岩中，通常与大量其他稀有矿物相邻。 ',
    },
    Fr: {
        name: '钫',
        translate_name: 'Francium',
        atomic_mass: '223,0197',
        serial_number: '087',
        color: '#A6A5A5',
        text: '钫是迄今为止发现的最后一种碱金属。最初，钫的发现者玛格丽特-佩雷（Marguerite Perey）希望将其命名为 "catium"，因为它的阳离子是所有已知元素中电性最强的。然而，由于该名称与猫有关，这一倡议被否决了。因此，该元素以其发现者的出生地法国命名。 \n\n在自然界发现的元素中，钫是最稀有的元素之一。这种金属最稳定的同位素——钫-223——的半衰期约为 22 分钟，这使得这种元素无法在放射性物质中找到任何用途。由于钫的放射性很强，它的温度会升高到足以熔化自身，而这并不困难——它的熔点只有 20 ℃左右。当然，这也给研究钫的化学和物理特性带来了困难——从很多方面来说，它仍然是元素周期表中的一匹 "黑马"。 ',
    },
    Ba: {
        name: '钡',
        translate_name: 'Barium',
        atomic_mass: '137,327',
        serial_number: '056',
        color: '#3CB2E0',
        text: '钡的化学性质与钙相似：它能与水和酸发生剧烈反应，被空气中的氧气氧化，并被硫酸根离子从溶液中析出。虽然钙化合物——石灰石、大理石、石膏——自古以来就为人所知，但它们都是由汉弗莱-戴维于1808年首次分离出来的。尽管如此，钙和钡的生物效应却截然不同：钙是生物元素之一，而钡则具有剧毒。 \n\n钡被广泛用于制造高温超导体：例如，由于发现了La2-xBaxCuO4化合物——已知的第一种此类材料，在35K时进入超导状态——1987年获得了诺贝尔奖。钡化合物还被用于医疗领域：BaSO4是一种著名的无毒造影剂，用于肠镜检查。此外，金属钡还作为所谓的"吸收剂"被用于射频灯中，即作为一种吸收气体的物质，以达到仪器内部的真空。例如，在射频灯的内表面可以看到一个银色的"头"。 \n\n在工业中，金属钡是通过电解熔体的BaCl2提取的，而BaCl2又是通过多级合成从BaSO4中获得的。 \n\n最常见的钡矿物是重晶石，即BaSO4。重晶石因其比重大而得名，即使是一小块重晶石拿在手上也感觉相当沉重——其密度为4.48g/cm3，大多数矿物的密度在 2 至 3 g/cm3之间。即使在古代，骗子们也会利用这一点——他们将重晶石粉添加到面粉和酸奶油中，以便卖得更贵，而重晶石粉本身是绝对无害的。 ',
    },
    Ra: {
        name: '镭',
        translate_name: 'Radium',
        atomic_mass: '226,0254',
        serial_number: '088',
        color: '#3CB2E0',
        text: '镭是居里夫妇在分析从铀矿石中分离出铀后留下的废料时发现的。镭衰变产生的许多放射性核素被命名为镭 A、镭 B、镭 C 等。后来人们才发现它们是其他化学元素的同位素。镭是钡的化学类似物，它与水反应生成氢氧化镭（II）。镭化合物因其强烈的放射性而具有放射性化学发光。 \n\n镭可用于短期照射，治疗皮肤、鼻粘膜和泌尿系统的恶性疾病。 镭经常被用来制作持续发光的发光涂料（用于标记仪器和手表的表盘），但现在通常被危险性较低的氚和钷-147 取代。有时，民用手表（包括腕表）也使用镭发光成分，但随着这种发光成分的退化，发光效果也消失了。但在需要维修手表时，总是存在吸入一丁点含镭荧光粉的风险。 \n\n镭通常是从铀矿石或从含铀矿物中浸出镭的天然水中提取的。 ',
    },
    La: {
        name: '镧',
        translate_name: 'Lanthanum',
        atomic_mass: '138,90547',
        serial_number: '057',
        color: '#f9d80c',
        text: '在化学上，镧和它的前身钡一样，与钙相似：也具有很强的化学活性。然而，它具有许多特殊的化学和生物化学特性，例如，在土壤中微量添加镧可显著提高栽培植物的生长速度，这在中国的农业中得到了积极的应用。虽然这一发现最初来自苏联科学家，但俄罗斯实际上并没有采用这种做法。 \n\n最有趣的镧化合物之一是氟化物LaF3，它已在工业和日常生活中得到广泛应用。它是一种固态离子导体（电荷载体为F-），因此可用于开发新一代充电电池，以取代常见的锂离子电池。镧本身也用于生产电源，例如，为世界上第一辆混合动力汽车丰田普锐斯生产镍氢电池就需要约15kg镧。从历史上看，镧的首次应用是为煤气灯创建一个加热网：当被燃烧器加热时，镧开始发光，同时将红外辐射能量转移到光谱的可见光区域。 \n\n在工业中获取镧是一个复杂的高科技过程，因为它的化学性质与其他稀土元素非常相似。经过仔细分离后，镧通过熔融电解从其氯化物中分离出来。 \n\n在自然界中，镧主要存在于矿物独居石中，独居石是一种镧、铈、钕的磷酸盐，通常含有几乎所有轻镧系元素的杂质，其化学式为/La, Ce, Nd）PO4。它的名字来源于希腊语 monázō —— "独居"，因为独居石通常以单个孤立晶体的形式存在。 ',
    },
    Ce: {
        name: '的',
        translate_name: 'Cerium',
        atomic_mass: '140,116',
        serial_number: '058',
        color: '#f9d80c',
        text: '铈是轻型镧系元素中最常见的金属，形成了一个铈族（从镧到铕）。有趣的是，这种元素能够形成四电荷阳离子，由于这种能力，金属很容易从稀土元素的溶液中释放出来。三价和四价都是着名的别洛乌索夫-扎甫京斯基振荡反应的催化剂，结果溶液的颜色由红色循环变为蓝色。 \n\n铈的主要应用是生产自燃合金。铈是混合稀土（稀土元素的合金）的主要成分。最广为人知的铁铈合金被用作引火成分，使得打火机、火镰等装置产生火花。 \n\n通过萃取和色谱法从稀土元素的混合物中浸出。通常，首先将各种氧化物的混合物溶解于浓硝酸。 \n\n自然界中最常见的铈存在于稀土磷酸盐中，但最美丽的矿物质是复合成分的硅酸盐异性石Na4（Ca、Ce、Fe、Mn）2ZrSi6O17（OH、Cl）2。这种美丽的深红色的矿物发现于碱和富含锌的岩石中，它们位于科拉半岛的基比尼地区。由于其外观，这种矿物在土着传说中被称为萨米人或拉普人血统。 ',
    },
    Pr: {
        name: '镨',
        translate_name: 'Praseodymium',
        atomic_mass: '140,90765',
        serial_number: '059',
        color: '#f9d80c',
        text: '长期以来，钕的"绿色孪生兄弟 "镨一直在小心翼翼地试图冒充钕的同类：经过46年的努力才确定这两种金属并非同一种物质。在空气中，镨会明显地吸收氧气和水分，使其表面像生锈的铁一样变质：一厘米长的镨锭在一年左右就会完全氧化。不过，镨也可以是一种氧化剂：Pr4+化合物甚至不存在于中性水溶液中，因为它们能极快地将水氧化成游离的氧气。 \n\n镨镍合金的一个有趣应用是通过绝热退磁获得极低的温度（千分之一度）。不过，镨更常用作陶瓷、玻璃和强永磁体的成分。此处展示的样本是一种黑色的双盐，即硼酸铁镨 PrFe3(BO3)4。 \n\n在工业中，金属镨是通过金属热或电解还原其盐类而获得的。 ',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
    },
    Nd: {
        name: '钕',
        translate_name: 'Neodymium',
        atomic_mass: '144,242',
        serial_number: '060',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '钕具有抗氧化性，很容易与水和矿物酸发生反应。在水溶液中，钕只以三电荷离子的形式存在，它能与多齿配体形成稳定的络合物。钕只有在与氟的络合物中才显示出四价。 \n\n钕是钛最重要的合金添加剂，可使钛的强度提高 1.5 倍。钕、硼和铁的合金形成了一种极其强大的永久磁铁。这种磁铁可用于医学领域的核磁共振成像仪、工业和电子领域。 \n\n在稀土元素分离过程中，钕与轻镧系元素一起浓缩，并与镨一起分离（这种混合物被称为钕镨）。 \n\n展台展示的是一种美丽的淡紫色晶体，其成分为 Nd3Ga5O12。 ',
    },
    Pm: {
        name: '钷',
        translate_name: 'Promethium',
        atomic_mass: '146,9151',
        serial_number: '061',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '钷在地壳中的总含量不超过几百克，因此很长时间都无法通过分析发现它。这是因为钷的放射性高，半衰期短：最长寿的钷同位素的半衰期约为 18 年。直到 1945 年，人们才利用离子交换树脂探测到钷，并将其从铀裂变产物中分离出来，此后又花了两年时间才证明它确实是镧系元素中的一种新化学元素。钷的化学性质与邻近元素相似，但由于其半衰期很短，对它的研究很少。 \n\n在钷的所有同位素中，只有 147Pm 有重要的应用价值。它被用于生产放射性同位素源和荧光粉，因为它实际上不会发射具有强大穿透能力的伽马量子。 \n\n由于在自然界中无法发现或提取到工业所需数量的钷元素，这种元素是通过用热中子轰击铀 235 来人工生产的。 ',
    },
    Sm: {
        name: '钐',
        translate_name: 'Samarium',
        atomic_mass: '150,36',
        serial_number: '062',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '钐之所以有趣，主要是因为它的名字——它是历史上第一个以真实人物（采矿工程师 Samarsky-Bykhovets）而不是神话人物命名的元素。钐是一种高活性金属，在空气中会形成一层深色的三价氧化物薄膜，然后完全碎裂成黄色粉末。这种金属很容易与几乎所有非金属发生反应，形成稳定的二元化合物。 \n\n钐可用于生产磁性和热电材料、核电工程和耐火材料。钐化合物，特别是 Sm3Ga5O12，可用于研究磁光效应。钐钴磁铁比钕磁铁能承受更高的温度。此外，钐磁铁不易腐蚀，通常不需要涂层。 \n\n金属钐可通过冶金热法和电解法获得，其中大部分是通过离子交换法从独居石砂中提取的。 ',
    },
    Eu: {
        name: '铕',
        translate_name: 'Europium',
        atomic_mass: '151,964',
        serial_number: '063',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '铕首先由克鲁克斯（Crookes）通过光谱方法发现，然后由勒克-德-博博德朗（Lecoq de Boabodran，也就是发现镓元素，并传说以自己名字命名镓的那位）发现，15 年后，德马塞（Demarse）分离出了纯净的铕。分离铕之所以困难，是因为在镧系元素中，铕的化学活性最高：一厘米长的铕块放在空气中，几天后就会完全变成氧化态。 \n\n在欧洲很容易找到铕：欧元钞票上都涂有含铕的荧光粉，以防止伪造。此外，含铕的荧光粉还广泛应用于黑色灯具和现已过时的等离子电视（产生红色）。铕在制造量子存储器上的应用有广阔的前景。展台中展示的是一个以铕为基础合成的橙红色化合物样品——铕镓石榴石 Eu2Ga5O12。 \n\n在工业中，金属铕是通过 Eu2O3 与镧或碳的高温还原以及 EuCl3 熔体的电解获得的。 ',
    },
    Gd: {
        name: '钆',
        translate_name: 'Gadolinium',
        atomic_mass: '157,25',
        serial_number: '064',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '钆属于镧系元素钇族，具有碱性。钆是一种相当强的还原剂，很容易从所有无机酸（氢氟酸除外）中置换出氢，在氢氟酸介质中钆会被钝化。天然钆由六种稳定同位素和一种不稳定同位素组成，其半衰期比宇宙年龄都长许多倍。 \n\n钆可用于生产钆镓石榴石（Gd3Ga5O12）和钆镓钪石榴石的单晶体。这种石榴石的特殊性质使我们能够在其基础上制造具有极高效率和超高激光辐射参数的激光系统。此外，利用钆离子的激发激光辐射，还可以制造出在近紫外线范围内工作的激光器。 \n\n钆可通过氟化钆或氯化钆与钙的还原反应得到。钆化合物可通过分离稀土金属氧化物馏分获得。 ',
    },
    Tb: {
        name: '铽',
        translate_name: 'Terbium',
        atomic_mass: '158,92535',
        serial_number: '065',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '铽和镱、铒、钇一样，是为了纪念瑞典的伊特比村而命名的。1787 年，人们在伊特比村附近发现了一种异常沉重的新矿物，其中含有 4 种新的化学元素。由于所有这些元素的化学性质都非常相似，经过离子交换技术和 100 多年的研究，才分离出纯净的镱。最初，含铽的部分被称为铒，含铒的部分被称为铽。 \n\n铽的主要应用是生产荧光粉：例如，现代移动设备屏幕中的绿色 LED 就含有铽化合物。将铽铁合金用作磁致伸缩材料也很有趣：当它被置于磁场中时，它的大小和体积都会发生变化，这种材料可用于大功率超声波发生器。不过，展台中展示的是以无色氟化铽。 \n\n在工业和实验室条件下，分离铽和其他稀土元素的最佳方法是离子交换法。如果需要获得纯金属，可将铽转化为氯化物或氟化物形式，然后用钙进行还原。 ',
    },
    Dy: {
        name: '镝',
        translate_name: 'Dysprosium',
        atomic_mass: '162,500',
        serial_number: '066',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '镝是一种不纯的稀有金属，具有强碱性。它很容易与氮和氟发生反应，在温度仅为 20 ℃时就会与氧气反应生成氧化物。镝盐具有极强的吸湿性，建议将其存放在带有除湿器的容器中。 \n\n镝是一种有效的催化剂，其离子可用于制造医用激光器。硼酸镝铁的反铁磁性晶体 DyFe3(BO3)4 由于具有磁电效应，可用于各种电子领域。三价氧化镝可从其他盐类中获得，如表中的硫酸镝 Dy2(SO4)3 * 8H2O，可用于合成红色发光荧光粉。 \n\n镝是通过钙与氯化镝或氟化镝反应得到的。 ',
    },
    Ho: {
        name: '钬',
        translate_name: 'Holmium',
        atomic_mass: '164,93032',
        serial_number: '067',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '钬及其化合物具有显著的物理和化学特性。例如，Ho2O3 氧化物会根据照射光线的不同而改变颜色：在日光下是黄色的，但在白色 RGB LED 的照射下会变成橙红色。在地壳中发现的所有元素中，钬的磁矩最大，可用于产生超强磁场。 \n\n2017 年 3 月，钬成为第一个原子可以直接记录信息的元素。如果用强大的电子流照射固定在氧化镁基板上的钬原子，它就可以改变磁自旋状态，即取0和1两个值，从而可以写入1比特的信息。众所周知，硼酸钬可用于核能。展台中展示的是混合的硼酸铥铝 HoAl3(BO3)4。 \n\n与其他镧系元素一样，在工业中，金属钬也是通过用钙还原三氟化钬来获得的。 ',
    },
    Er: {
        name: '铒',
        translate_name: 'Erbium',
        atomic_mass: '167,259',
        serial_number: '068',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '铒是钇亚族中一种柔软而可塑的金属，在溶液中形成粉红色和紫色的盐类。与其他稀土元素一样，铒在自然界中不是以游离态存在，而是存在于独居石砂中。铒有 6 种稳定同位素和 29 种不稳定同位素，以及 13 种核异构体。 \n\n铒最重要的用途之一是以氧化物的形式应用于核技术。例如，氧化铒和氧化铀的混合物可以通过平衡能量分布来显著改善核反应堆的运行。在生产超长距离光纤时，氧化铒被添加到二氧化硅熔体中。掺入这种稀土元素的光纤能够吸收一种波长的光，并发射另一种波长的光。 \n\n由于稀土金属硼酸盐晶体中可能存在一些禁止跃迁，因此这种晶体可用于光量子发生器。展台上展示的是一种钆和铁的复合硼酸盐，其结构中引入了少量的铒。 ',
    },
    Tm: {
        name: '铥',
        translate_name: 'Thulium',
        atomic_mass: '168,93421',
        serial_number: '069',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '铥是一种阳电性较强的元素，具有化学活性高的特点。它很容易与氧气、水、卤素、无机酸以及任何具有氧化特性的物质发生作用。通常，Tm3+ 化合物会发出明亮的蓝色荧光。 \n\n铥的一个有趣应用领域是在医学领域。放射性同位素 170Tm 用于便携式 X 射线发生器，该发生器可用于牙科诊断。它还用于癌症肿瘤的近距离放射治疗：在这种治疗方法中，放射性药物被直接植入肿瘤组织，从而对受影响的组织进行选择性照射。稳定同位素铥的离子被用于激光材料，以产生近光谱区的红外辐射。展台上展示的是一种铥稳定同位素的化合物——硝酸铥 Tm(NO3)3 * 5H2O，它是一种淡绿色的晶体物质。 \n\n在工业中获得金属铥的方法与获得钬、铽和其他镧系元素的方法类似——用钙还原三氟化金属。 ',
    },
    Yb: {
        name: '镱',
        translate_name: 'Ytterbium',
        atomic_mass: '173,04',
        serial_number: '070',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '镱是一种镧系元素，具有碱性。在碱金属汞合金的环境中，镱在盐酸中的氧化度为+2，与镧系元素的氧化度不同。镱的二价盐是极强的还原剂，在水环境中会立即转变为三价状态。 \n\n镱可用于生产磁性材料、各种电子产品和激光器。因此，镱离子可用于产生近红外范围的激光辐射，氧化镱可用于生产高功率光纤激光器。  \n\n镱的主要生产方法是在真空中用碳或镧还原氧化镱（III），以及电解氯化物熔体。展台上展示的是一种复杂的含镱化合物：Y0.7Yb0.2Tm0.1 Al3(BO3)4。 ',
    },
    Lu: {
        name: '镥',
        translate_name: 'Lutetium',
        atomic_mass: '174,967',
        serial_number: '071',
        color: '#f9d80c',
        link: '样品由俄罗斯克拉斯诺亚尔斯克俄罗斯科学院西伯利亚分院 L.V. Kirensky 物理研究所提供',
        text: '镥是镧系元素中最重、最硬、最难熔的一种。然而，这种金属的化学性质与其他稀土元素相似：它也具有高活性。镥被命名为 "Lutetia Parisorum"（巴黎镥），以纪念巴黎在拉丁语中的名称，不过也有人希望将其命名为 "Cassiopium"（以纪念一个星座），甚至直到 20 世纪 60 年代，德国人还一直这样称呼它。 \n\n由于镥的价格昂贵，其在工业和日常生活中的应用十分有限。质量数为 177 的镥同位素用于正电子发射诊断和神经内分泌肿瘤的放射性核素治疗。钽酸镥 LuTaO4 是科学界已知的所有稳定白色物质中最重的一种（密度为 9.81 g/cm3），因此是制造 X 射线荧光粉基底的理想材料。镥与其他稀土元素一样，会与其他阳离子形成双盐：例如，双镥铝硼酸盐 LuAl3(BO3)4 是一种无色结晶物质。 \n\n镥的工业生产与其他稀土元素类似：通过离子交换从其他稀土元素中分离出金属，转化为氯化物或氟化物的形式，并通过钙热还原氟化镥。 ',
    },
    Hf: {
        name: '铪',
        translate_name: 'Hafnium',
        atomic_mass: '178,49',
        serial_number: '072',
        color: '#cfcfcf',
        text: '铪是锆的类似物：它们的密度和熔点都很高，化学活性很低（尽管铪在所有参数上都优于锆）。然而，成分为 Ta4HfC5 的化合物——碳化钽铪——在耐火度上超过了这两种金属，也超过了目前已知的所有材料：Ta4HfC5 的熔点为 3990 ℃。计算表明，Hf-C-N 系统的耐火度甚至可以超过这种化合物，但这种物质尚未通过实验获得。 \n\n虽然铪与锆非常相似，但在中子俘获能力这一参数上却截然相反。铪能很好地吸收中子，而锆则相反，能很好地让中子通过，因此前者被用于制造核反应堆的控制棒，而后者则被用于制造铀裂变燃料棒。因此，必须将锆铪分离，这是一项艰巨的任务，因为 Hf 和 Zr 是类似物。铪还用于制造等离子切割机的阴极，因为它具有高耐火度和相对较低的电子逸出功。这种电极在二氧化碳中的电阻比耐火度较高的钨电极高出约 3.7 倍。 \n\n在矿石中，铪与锆的含量很少，因此铪与锆一起生产。要获得金属铪，需要在 1100 ℃的高温下用镁还原氟化铪。 \n\n虽然铪可以形成独立的矿物，但它通常作为杂质存在于锆矿物中，其中最常见的是斜锆石ZrO2。斜锆石常见于含硅量较低的火成岩中，在某些矿床中，斜锆石的铪含量高达 2-3 wt%，足以从此类矿石中回收铪。 ',
    },
    Ta: {
        name: '钽',
        translate_name: 'Tantalum',
        atomic_mass: '180,9479',
        serial_number: '073',
        color: '#cfcfcf',
        text: '闪亮的银白色金属钽是为了纪念希腊神话中注定要受永恒折磨的英雄而命名的，因为要获得纯钽是非常困难的。在正常情况下，钽是不活泼的，只有在加热时才会与活泼的非金属发生反应。此外，它不溶于非氧化性酸，不与任何浓度的硫酸发生反应，甚至对于王水也是惰性的。 \n\n钽可用于制造耐热和耐腐蚀合金、核系统热交换器和航空航天部件。只有铝和钽这两种金属在氧化时能形成致密、不导电的氧化膜，其厚度可以控制。因此，钽是固态电容器的主要材料之一。 \n\n金属钽通常是通过用碳还原氧化钽（V）或从其熔体中通过电化学方法获得的。 \n\n钽的主要矿物之一是铌铁矿，更确切地说，是铌铁矿族矿物。在这些矿物中，同构现象非常普遍，成分中的元素很容易相互混合，因此可给出这些矿物的通式（Mn，Fe）（Ta，Nb）2O6。这些矿物的形成通常也与特殊成分的花岗伟晶岩有关，在这些伟晶岩中，铌和钽的浓度会增加。 ',
    },
    W: {
        name: '钨',
        translate_name: 'Wolframium',
        atomic_mass: '183,84',
        serial_number: '074',
        color: '#cfcfcf',
        text: '钨是门捷列夫化学元素周期表中最难熔的金属。比它更难容的是非金属碳，但碳只有在高压下才会熔化。在溶液中，它往往会形成杂多酸及其阴离子：例如 H2W12O4210- 和 H3PW12O40。 \n\n大约一半的钨用于生产碳化钨，碳化钨是一种非常坚硬（莫氏9级）的耐磨材料，广泛用于工业和日常生活。金属钨用于制造各种机械装置的耐火部件，其中最著名的是照明设备中的灯丝。当电流通过时，钨会发热并开始发出连续光谱的光。 \n\n在工业中，金属钨是通过用氢气还原三氧化钨获得的。 \n\n钨是从几种矿物中提取的，其中最常见和最重要的是白钨矿CaWO4。它的晶体看起来很轻，通常是白色或淡黄色的金字塔形，具有很强的光泽。如果把白钨矿晶体拿在手上，你会立刻感觉到它的重量！这并不奇怪，因为钨是一种非常重的金属，而白钨矿中含有大量的钨。 ',
    },
    Re: {
        name: '铼',
        translate_name: 'Rhenium',
        atomic_mass: '186,207',
        serial_number: '075',
        color: '#cfcfcf',
        text: '铼是唯一不与碳形成碳化物（二元化合物）的金属。对铼化合物的研究使人类收获了一个以前无法想象的发现：铼能够形成四重化学键！在沸点方面，这种金属位居第一，仅比钨高出几十度。 \n\n由于铼的成本极高，其应用非常有限。不过，铼的应用领域与它的非凡特性相吻合：铼-钨热电偶可以测定高达 2200 ℃的温度。 \n\n在自然界中，铼主要以天然形式或硫化铼（褐斜闪石）ReS2 的形式存在。这些矿物的粒度确实非常微小，但它们总是存在于展台上展示的诺里尔斯克矿石中，尽管数量很少。 ',
    },
    Os: {
        name: '锇',
        translate_name: 'Osmium',
        atomic_mass: '190,23',
        serial_number: '076',
        color: '#cfcfcf',
        text: '锇是门捷列夫化学元素周期表中真正的 "野蛮人"：它坚硬、难熔、难以加工，而且重得惊人——是所有单质中最重的一种。锇的化学性质也证实了它的名声：在紧密状态下，锇既不与酸反应，也不与碱反应。然而，即使是如此坚硬的“辣椒”也有其女性化的小弱点，其中最主要的就是氧气。粉末状的锇在氧气的氧化作用下会形成高挥发性、剧毒的四氧化物 OsO4，具有令人作呕的气味，锇也因此得名：古希腊语 ὀσμή意为 "气味"。 \n\n由于 OsO4 的高挥发性和毒性，纯锇很少用于日常生活和工业用途，但其合金却并非如此：Os-Ir 被广泛用于制造昂贵的钢笔，而 Pt-Os 则被用于制造心脏起搏器和其他外科植入物。 \n\n锇很容易从其他铂金属中分离出来：当含锇精矿在空气中煅烧时，锇会氧化并升华为四氧化物，然后被氢氧化钠捕获，生成的盐——过锇酸钠/Na2[OsO2(OH)4]——会被氢进一步还原。钌也会形成挥发性四氧化物，通过沉淀其复合铵盐来分离。 \n\n可以猜到，锇在自然界中很少被发现。锇通常以与铱的天然合金形式存在，有时也与铑形成合金——铱锇矿和钌铱锇矿。这些矿物在矿石中的粒度通常只有几毫米，展台中展示的是诺里尔斯克矿石中，锇通常以杂质的形式存在于其他铂族金属的矿物中。 ',
    },
    Ir: {
        name: '铱',
        translate_name: 'Iridium',
        atomic_mass: '192,217',
        serial_number: '077',
        color: '#cfcfcf',
        text: '铱的名称来自古希腊语，意为 "彩虹"，因为铱的化合物具有不同的颜色。铱在地球岩石中极为罕见，但它在地球上的实际含量可能要高得多：铱的高密度和对铁的高亲和力可能导致铱被转移到地球深处。在温度低于 100 ℃时，紧凑型铱不会与所有已知的酸及其混合物发生反应。 \n\n铱可用于生产多种合金：铂铱合金具有很高的机械强度，并且不会氧化。例如，公斤标准就是用这种合金制成的。铱还用于制造钢笔。在钢笔和墨水笔的笔尖上，特别是在金笔上可以看到一个小铱球，它的颜色与钢笔本身的颜色不同。 \n\n铱的主要来源是铜镍生产过程中产生的阳极泥。 \n\n铱和其他铂族金属一样，在自然界非常稀少。大多数情况下，铱要么是与锇和钌的天然合金——铱锇矿，要么是其硫化物——柯硫铑铱矿Ir2S3，柯硫铑铱矿通常还含有铑。诺里尔斯克矿石中含有少量铱，但肉眼无法看到——它们非常小。 ',
    },
    Pt: {
        name: '铂',
        translate_name: 'Platinum',
        atomic_mass: '195,084',
        serial_number: '078',
        color: '#cfcfcf',
        text: '铂被发现后，由于其密度大、耐折射性强，其价值只有白银的一半，因此在很长一段时间内没有被使用。然而，随着其加工技术和应用（催化）的发展，铂的价格急剧上升，超过了黄金的价格。虽然铂本身的化学性质极度惰性，但它的一些化合物却具有最高的化学活性：例如，六氟化铂PtF6能氧化惰性气体氙和氧，形成盐类化合物[XeF][PtF6]和[O2][PtF6]。 \n\n在日常生活中，铂不仅在珠宝店里很容易找到，在最普通的汽车里也很容易找到。铂与其他元素（包括稀土元素）制成合金，用于制造点火器的中心电极，在内燃机中产生火花。此外，顺铂、卡铂等铂化合物作为细胞抑制剂被广泛用于各种癌症的治疗。 \n\n在工业中，铂是加工含镍和铜矿石的副产品。贵金属精矿在王水中分解，铂金以(NH4)2[PtCl6]的形式从氯化铵中析出，氯化铵在加热时分解，释放出游离铂。 \n\n展台中展示的诺里尔斯克矿石中含有铂，但是以几种化合物的形式存在的铂：铁和铂的天然合金——异铁铂和四铁铂，以及砷化铂——钟铂矿。有时，钟铂矿晶体的直径可达1cm，甚至更大！但这种晶体根本不可能通过合法途径获得。不过，可以肯定的是，这块矿石中含有微量的铂矿物提取物。 ',
    },
    Au: {
        name: '金',
        translate_name: 'Aurum',
        atomic_mass: '196,966569',
        serial_number: '079',
        color: '#cfcfcf',
        text: '金是财富和奢华的象征，是一种柔软、低活性的黄色金属。金的颜色之所以不同于大多数金属，是因为半填充的 6s 轨道和填充的 5d 轨道之间的能隙很小。金能够与非常活泼的碱金属（铯和铷）形成化学键，在这些化合物中，金的氧化度为-1，这在金属中是不常见的。 \n\n黄金的传统和最大消费者是珠宝业。首饰不是由纯金制成的，而是由它与其他金属的合金制成的，这些合金在机械强度和抗性方面比黄金要好得多。金是一种极好的电流导体，因此被广泛用作电接触材料，也是一种极好的防腐蚀材料。 \n\n目前，从矿石中提取金最常用的方法是化学方法：混汞法、氰化法等，但有时也使用重力法从轻得多的废石中分离重金。 \n\n自然界中最著名的金矿形式是自然金，但现自然金已相当罕见。开采的大多是 "隐形 "金——其颗粒大小为十分之一或百分之一毫米！这里的矿石来自俄罗斯最大的矿床之一——奥利匹亚达矿床。 ',
    },
    Hg: {
        name: '汞',
        translate_name: 'Hydrargyrum',
        atomic_mass: '200,59',
        serial_number: '080',
        color: '#cfcfcf',
        text: '汞是一种重液态金属，呈银白色，自古以来就为人类所知。在化学上，汞有许多独特之处：例如，它是形成 (Hg-Hg)2+ 形稳定簇的几种元素之一，以这种形式存在于许多无机和有机化合物中。它还能形成一些最稳定的有机金属化合物：它的相对稳定性使二甲基汞/Hg(CH3)2 成为最早合成的同类化合物之一。 \n\n长期以来，汞以硫化物——朱砂 HgS 的形式被用于治疗梅毒，并作为红色颜料出现在经典圣像画中。如今，人们在水银温度计中仍能发现汞，但由于汞的毒性，水银温度计正被红外线温度计和电子温度计所取代。水银受热后，其密度和体积会发生变化，表现为温度计内液面的变化。同时，汞本身在零下 39 ℃时仍保持液态，其与铊的合金在零下 61 ℃时仍保持液态，这使其可用于低温温度计。 \n\n在工业中，金属汞是通过在有氧或金属热的情况下焙烧从硫化物中提取的。在此过程中，汞被蒸馏出来，以液态形式被收集。 \n\n展台上展示的是硫化汞——朱砂。这是一种非常漂亮的深红色矿物，我们的祖先认为它是凝固的龙血。有趣的是，朱砂通常形成于火山活动或温泉改变过的岩石中。 ',
    },
    Rf: {
        name: '鑪',
        translate_name: 'Rutherfordium',
        atomic_mass: '267',
        serial_number: '104',
        color: '#cfcfcf',
        text: '鑪是第一种反式元素，其所有同位素都具有最高的放射性：其中最稳定的同位素 267Rf 的半衰期约为 1.3 小时。它的名字是为了纪念英国著名物理学家欧内斯特-卢瑟福，他是原子行星模型的创造者。从形式上看，鑪是铪的化学类似物，但由于相对论效应，它们的性质可能会有很大不同。它的计算密度为 23.2 g/cm3，比从宏观量获得的最重金属——锇的密度高出约 0.6 g/cm3。 \n\n与其他超重放射性元素一样，鑪的使用仅限于研究目的。 \n\n鑪可用以下方法来获得：用 26Mg 离子辐照 238U，用 22Ne 核辐照 242Pu，用 12C 离子辐照 249Cf，以及用电子俘获 263Db 和其他实验方法。 ',
    },
    Db: {
        name: '𨧀',
        translate_name: 'Dubnium',
        atomic_mass: '268',
        serial_number: '105',
        color: '#cfcfcf',
        text: '铌（Nielsborium）、镓（ganium）、钶（zholiotium）——所有这些名字都曾在不同国家被叫做第 105 种元素，直到 1997 年，为了纪念俄罗斯核物理研究中心，它才被正式命名为 "𨧀"。化学计算显示，在水溶液中，"𨧀 "的最大氧化度等于 +5，其化学性质更接近于铌，而不是钽。 \n\n这种元素是在杜布纳用氖-22 离子轰击镅-243 和在伯克利用锎-249 与氮-15 反应得到的。 \n\n目前已知的𨧀同位素有 13 种，其中半衰期最长的约为 29 小时。 ',
    },
    Sg: {
        name: '𨭎',
        translate_name: 'Seaborgium',
        atomic_mass: '269',
        serial_number: '106',
        color: '#cfcfcf',
        text: '𨭎元素是一种放射性极强的反式系列元素，为纪念美国著名化学家和核物理学家格伦-西博格（Glenn Seaborg）而命名，他是门捷列夫表中十种元素（西硼元素是其中之一）的发现者或共同发现者。1974 年，加利福尼亚伯克利研究所劳伦斯实验室用氧-18 原子轰击锎-249，首次合成了𨭎。与此同时，苏联杜布纳的科学家通过用铬-54 原子轰击铅-208 和铅-207 合成了它。𨭎 是钨和钼的类似物：它在氧化度 +6 时稳定，并倾向于形成阳离子 SgO22+，以这种形式存在于含有各种阴离子的盐中。 \n\n𨭎仅用于研究目的，因为即使是最稳定的同位素 269Sg，其半衰期也只有 14 分钟左右。 \n\n热核反应有两种类型——"冷 "核聚变和 "热 "核聚变——用于生产𨭎。在第一种情况下，形成的原子核处于低能量（约 10-20 MeV）的激发态，在过渡到基态的过程中会失去 1-2 个中子；此类反应包括铅-208、207 或 206 和铬-54、铅-206 和铬-52 以及铋-209 和钒-51 的聚变。在第二种情况下，聚变后的激发核具有高能量（40-50 MeV），需要发射 4-5 个中子来 "冷却"；这是 238U 和 30Si、248Cm 和 22Ne、249Cf 和 18O 等的反应。 ',
    },
    Bh: {
        name: '硼。',
        translate_name: 'Bohrium',
        atomic_mass: '270',
        serial_number: '107',
        color: '#cfcfcf',
        text: '第 107 号元素钅波是以杰出的丹麦化学家尼尔斯-玻尔的名字命名的。钅波是一种人造化学元素，在自然界中无法见到。所有已知的钅波同位素都具有极强的放射性，其中最稳定的同位素的半衰期约为 61 秒。 \n\n最初，钅波是在奥加内斯扬的领导下，在杜布纳研究铋-209 和铬-54 原子核聚变反应产物的自发裂变时获得的，但直到 5 年后，来自德国的科学家才完全确定了这种元素的特性，并确定了所获得同位素的半衰期。 ',
    },
    Hs: {
        name: '𨭆',
        translate_name: 'Hassium',
        atomic_mass: '277',
        serial_number: '108',
        color: '#cfcfcf',
        text: '𨭆是 1984 年在重离子研究中心（德国达姆施塔特）通过用 58Fe 离子轰击 208Pb 靶件首次合成的一种反式重金属元素。这种新金属只合成了 3 个原子核，并通过其发射的α粒子进行了检测。与此同时，在杜布纳也进行了类似的实验，但苏联科学家无法探测到𨭆本身的衰变，而是根据其衰变产物之一——253Es——的发射数据得出结论，因此这一发现被德国人优先获得。它以德国黑森州（达姆施塔特市所在地）的名字命名。 \n\n由于在一次实验中通常只能得到十几个𨭆原子，因此它只用于研究。在实验中，人们发现𨭆是锇和钌的类似物：它能形成挥发性四氧化物 HsO4、盐 Na2[HsO2(OH)2] 和类似二茂铁、二茂钌和二茂锇的夹心配合物  Hs(C5H5)2。 \n\n利用铁离子轰击铅靶以及 226Ra、48Ca、249Cf、22Ne、238U、36S、238U、34S、248Cm 和 26Mg 的核聚变反应来生产钾。 ',
    },
    Mt: {
        name: '䥑',
        translate_name: 'Meitnerium',
        atomic_mass: '278',
        serial_number: '109',
        color: '#cfcfcf',
        text: '䥑是一种合成元素，属于过渡金属。根据计算数据，䥑是一种贵金属，其化学性质与银相似。当与氟结合时，䥑的氧化度有可能达到极其罕见的 +9。令人惊讶的是，䥑是唯一一种化学性质尚未得到研究的元素。 \n\n该元素是在达姆施塔特通过用铁-58 原子轰击铋-209 首次获得的。',
    },
    Ds: {
        name: '鐽',
        translate_name: 'Darmstadtium',
        atomic_mass: '281',
        serial_number: '110',
        color: '#cfcfcf',
        text: '鐽是德国达姆斯塔特重离子研究中心（GSI）合成的第四种反式元素，鐽也因此而得名。达鐽最重、最稳定的同位素 281Ds 的半衰期约为 12.7 秒。在化学上，它是门捷列夫表第十族中较轻元素——镍、钯和铂——的类似物，但由于相对论效应，鐽的化学性质与其最 "亲近 "的铂有些不同。例如，后者形成氧化度为 +2 的氰化物络合物，而鐽与相同的配体形成二离子 [Ds(CN)2]2-，其中的中心原子不带电荷，Ds-C 键具有双键性质。 \n\n鐽仅用于有限的研究目的。 \n\n鐽同位素由 208Pb 和 62Ni、208Pb 和 64Ni、244Pu 和 34S 的聚变反应产生。 ',
    },
    Rg: {
        name: '錀',
        translate_name: 'Roentgenium',
        atomic_mass: '282',
        serial_number: '111',
        color: '#cfcfcf',
        text: '錀是一种低活性的过渡惰性金属，类似于金。由于惰性金属的活性随序号的增加而降低，因此可以认为錀的活性比金还低，是化学性质最惰性的金属。该元素是为了纪念发现 X 射线的威廉-康拉德-伦琴而命名的。 \n\n錀的首次合成是通过铋-209 与镍-64 的反应进行的，生成的同位素的半衰期估计只有 1.5 毫秒。已知寿命最长的氘同位素的半衰期为 2.1 分钟，质量数为 282。 ',
    },
    Cn: {
        name: '鎶',
        translate_name: 'Copernicium',
        atomic_mass: '285',
        serial_number: '112',
        color: '#cfcfcf',
        text: '鎶是一种为纪念尼古拉斯-哥白尼而命名的反式元素，它于 1996 年在达姆施塔特通过 208Pb 和 70Zn 原子核的融合首次获得。尽管从形式上看，鎶是锌、镉和汞的类似物，但计算表明，在晶体状态下，鎶原子是通过分散力结合在一起的，就像在惰性气体晶体中一样。正因为如此，它可能是整个门捷列夫化学元素周期表中反应性最小的元素，其物理特性使它成为半导体甚至绝缘体。 \n\n最稳定的鎶同位素 285Cn 的半衰期约为 29 秒，因此这种元素的使用仅限于研究目的。 \n\n208Pb 和 70Zn、238U 和 48Ca 核的聚变反应可用于生产鎶。此外，还可以从鿫-294、鉝-290 和鈇-286 的α衰变中获得鎶。 ',
    },
    Ac: {
        name: '锕。 ',
        translate_name: 'Actinium',
        atomic_mass: '227,0278',
        serial_number: '089',
        color: '#f7ad2c',
        text: '锕是一种重放射性金属，外观与镧相似。它与铀矿石伴生，自然界中只记录了 3 种锕同位素。这种放射性金属在地壳中极为稀少：1吨铀矿石可产生约 0.2 毫克的锕-227，而1吨钍矿石只能产生 5 ng的锕-228。锕是具有类似化学性质的放射性金属——锕系元素的祖先。 \n\n锕-227 可用于活化分析，测定矿石中的锰、硅和铝，锕-228 可作为伽马射线的强辐射源用于医学研究。锕的放射性还可用于放射免疫治疗和制造放射性同位素电源。 \n\n由于铀和钍矿石中的锕含量较低，因此只能通过人工方式获得。 同位素 227Ac 是在反应堆中用中子辐照镭产生的。这种合成方法的锕含量以克为单位计算。同位素 228Ac 是用中子辐照同位素 227Ac 产生的。 ',
    },
    Th: {
        name: '钍',
        translate_name: 'Thorium',
        atomic_mass: '232,03806',
        serial_number: '090',
        color: '#f7ad2c',
        text: '钍是一种银白色的弱放射性金属，在空气中会因氧化而变暗。在从锕到锎的锕系元素中，它的熔点和沸点最高，密度也几乎最低（只有锕更轻）。它还具有高反应性的特点，甚至超过了铝等元素。 \n\n钍不能通过热中子裂变，也不是核燃料，因此其用途仅限于和平目的。以前，钍的氧化物被广泛用于生产煤气灯中的加热网。它们利用烛光现象：当材料受热时，自由基在其表面重新结合，释放出可见光范围内的光。 \n\n在工业中，金属钍是通过四氟化铀的钙热还原法或熔融电解法生产的。 \n\n钍在自然界中经常被发现，但通常是以非常小的含钍矿物颗粒的形式存在。如果仔细观察所展示的样品，就会发现黑色小颗粒的矿物拉普兰石（Na, Ce, Ca, Th）（Ti, Nb）O3。你不应该害怕它——钍本身的放射性很弱，这种矿物只含有少量的钍。这种矿物存在于特殊成分的岩石中，例如在科拉半岛的基比尼山中就能找到。 ',
    },
    Pa: {
        name: '镤',
        translate_name: 'Protactinium',
        atomic_mass: '231,03588',
        serial_number: '091',
        color: '#f7ad2c',
        text: '镤是锕的前体，是一种相当活泼的金属，很容易与氧、碘和氢形成二元化合物。就物理特性而言，镤的硬度与铀相似，在 2K 左右的温度下具有超导性。镤的最长寿和最丰富的同位素镤-231 的半衰期为 32 760 年，是铀-235 的衰变产物。 \n\n由于其在地壳中的含量低且毒性极强，该元素的用途非常狭窄——核燃料添加剂。镤元素的含量为每吨铀 0.34 g。 \n\n金属镤是通过在 1400-1500°C 的温度下用钡或钙蒸气还原 PaF4 而得到的。而镤-231 本身可以通过慢中子辐照钍-230 而获得。 ',
    },
    U: {
        name: '铀',
        translate_name: 'Uranium',
        atomic_mass: '238,02891',
        serial_number: '092',
        color: '#f7ad2c',
        text: '铀是门捷列夫化学元素周期表中的第二个不归点：它后面的所有元素在自然界中几乎都找不到，都是人工合成的。然而，正是由于铀，人类才走过了另一个也许更为重要的点：安托万-亨利-贝克勒尔（Antoine Henri Becquerel）正是在研究铀的过程中发现了放射性衰变，从而引发了一连串的新发现、新理论，并最终引发了一场科学革命。虽然铀可能是所有放射性元素中最著名的一种，但它的放射性活度很低：最常见的同位素 238U 的半衰期为 4.47*109 年，相当长。 \n\n铀最耸人听闻的用途是用于核武器，但它也用于和平目的：例如，用于核电工程和铀玻璃的熔炼。铀玻璃具有特有的黄绿色，在紫外线照射下会发出荧光。尽管在小型紫外线光源发明之前，大多数消费者并没有意识到铀玻璃会发出荧光，但人们自古以来就在制造铀玻璃。 \n\n在工业中，铀是通过钙或镁热还原四氟化铀获得的。个别同位素的分离是通过六氟化铀的气体离心来进行的：不同的铀同位素在其中的表现不同，因为它们的质量不同。 ',
    },
    Np: {
        name: '镎',
        translate_name: 'Neptunium',
        atomic_mass: '237,0482',
        serial_number: '093',
        color: '#f7ad2c',
        text: '有三组研究人员试图获得第 93 种元素，但都没有成功，不过，尽管尚未被发现，它已经有了三个名字：Ausonium、Bohemium 和 sequanium。这种锕系元素于 1940 年首次合成，当时被命名为镎。镎是一种活性中等的重金属，在水溶液中会形成多色氧化物，容易水解和络合。 \n\n镎的天然来源没有实际意义。 \n\n目前，镎是从核反应堆中长期辐照铀的产物中提取的，是提取钚时的副产品。 \n\n镎核是通过核反应从铀中获得的，金属镎则是通过还原其四氟化物获得的。 ',
    },
    Pu: {
        name: '钚',
        translate_name: 'Plutonium',
        atomic_mass: '244,0642',
        serial_number: '094',
        color: '#f7ad2c',
        text: '钚的名字来源于仅次于天王星的冥王星。这一事实颇具讽刺意味，因为冥王星本身就是以古罗马的死神冥王（类似于古希腊的哈迪斯）的名字命名的。随着时间的推移，冥王星名副其实：第一颗原子弹就是用它的同位素 239Pu 制造的。 \n\n钚也有和平用途：例如，它被用于制造航天器和核潜艇的电源。238Pu 的一个有趣应用是为 "永恒 "心脏起搏器制造电源，这种起搏器可以工作几十年而无需更换。 \n\n在工业领域，钚是从核反应堆中的铀裂变废料中产生的。 ',
    },
    Am: {
        name: '镅',
        translate_name: 'Americium',
        atomic_mass: '243,0614',
        serial_number: '095',
        color: '#f7ad2c',
        text: '镅是第 95 种元素，以美国的名字命名，是一种重金属，具有延展性，由于自身的α辐射，在黑暗中也能发光。1944 年镅的发现是保密的，这对于战争期间产生的放射性元素来说并不奇怪。不过，值得注意的是，公开宣布镅的发现不是在科学会议上，而是在一个儿童广播节目中。 \n\n寿命最长的镅同位素半衰期超过 7 000 年，被用于放射化学研究。半衰期约为 433 年的镅 241 存在于一些烟雾探测器中（每个探测器约 260 毫微克）。 \n\n镅 241 的商用数量来自钚 241 的衰变，钚 241 是铀 238 在所有核反应堆中俘获一个中子后产生的。 ',
    },
    Cm: {
        name: '锔',
        translate_name: 'Curium',
        atomic_mass: '247,0703',
        serial_number: '096',
        color: '#f7ad2c',
        text: '锔是锕系元素中的一种高放射性超铀元素，是为了纪念皮埃尔-居里和玛丽-居里而命名的。它于 1944 年首次合成，是α粒子轰击 239Pu 的结果，同时形成了锔的化学类似物——镅。分离这些元素是一项极其困难的任务，因为它们的化学性质几乎完全相同。因此，它们最初甚至被称为 "pandemonium "和 "delirium"，即拉丁语中的 "地狱 "和 "谵妄"。 \n\n锔的放射性非常高，在小巧的形态下，由于其自身的辐射，会发出紫色的光。同时，244Cm 几乎不发射β射线和γ射线，而β射线和γ射线具有很强的穿透能力，因此可以在其基础上制造具有相当小的保护壳的装置。这被广泛用于制造航天工业和医学（心脏起搏器）的小型发电机，以及用于分析火星土壤样本的α粒子 X 射线光谱仪（APXS - α粒子 X 射线光谱仪）。 \n\n质量数为 242 和 244 的锔同位素是从核反应堆的乏燃料中提取的，从实用角度来看，这两种同位素最令人感兴趣。由于锔的需求量远远超过供应量，俄罗斯联邦制定了特别计划，以提高核反应堆中锔同位素的产量。Cm也可以通过定向反应合成，即用中子照射钚 239。 ',
    },
    Bk: {
        name: '锫',
        translate_name: 'Berkelium',
        atomic_mass: '247,0703',
        serial_number: '097',
        color: '#f7ad2c',
        text: '锫是一种超铀锕系元素，是铽的化学类似物。锫的主要特征是其四价态的稳定性，这使得锫化合物与其他锕系元素和镧系元素的化学分离成为可能。锫是一种相当活跃的金属。它的特点是能形成有机金属化合物和复合化合物。 \n\n锫可用于生产锎和鿬的同位素。 \n\n镅或锔的相应同位素以及铀-238 或钚-239 的中子辐照均可产生锫。 ',
    },
    Cf: {
        name: '锎',
        translate_name: 'Californium',
        atomic_mass: '251,0796',
        serial_number: '098',
        color: '#f7ad2c',
        text: '锎是人类已知的最昂贵的金属之一，并已被实际应用：一克锎价值约 400 万美元（约为黄金价格的 84000 倍）。锎以加利福尼亚大学伯克利分校命名，1950 年首次在该校合成。正如作者自己所写的那样，他们发现锎就像美国第一批居民到达加州一样困难，因此最终以此来为新元素命名。 \n\n由于昂贵的成本、复杂的合成途径和巨大的中子放射性，锎的使用受到了限制。半衰期约为 2.645 年的 252Cf 同位素被用作中子活化分析和癌症肿瘤中子治疗的高效中子源：1 mg这种物质每分钟可发射约 1.39 亿个中子。冷战期间，有人试图用它来生产所谓的 "原子弹"——12.7 mm子弹大小的微型核装药，其爆炸可熔化坦克装甲。 \n\n据估计，一年合成的锎总量达数十毫克。为此，用中子轰击一个由锫-249 制成的靶件，然后从反应产物中提取质量数为 250、251 和 252 的锎同位素。 ',
    },
    Es: {
        name: '锿',
        translate_name: 'Einsteinium',
        atomic_mass: '252,0829',
        serial_number: '099',
        color: '#f7ad2c',
        text: '锿元素是一种典型的锕系元素，由于具有极高的放射性，在正常情况下会发光。在溶液中，这种金属最常见的是三电荷阳离子，具有明显的绿色。所有的锿化合物都具有放射性——三价碘化锿具有明亮的红光。 \n\n目前，除放射化学研究外，锿还未在其他任何地方使用。 \n\n锿是通过用氘轰击锎-249 以及铒-249 与α粒子发生核反应而获得的。金属锿由锂从其氟化物还原而成，由镧从其氧化物还原而成。 ',
    },
    Fm: {
        name: '镄',
        translate_name: 'Fermium',
        atomic_mass: '257,0951',
        serial_number: '100',
        color: '#f7ad2c',
        text: '镄是门捷列夫化学元素周期表中的第三个不归点：它后面的元素无法从乏核燃料中分离出来，因此只能通过定向核反应合成。1952 年，美国洛斯阿拉莫斯实验室的科学家首次获得了这种元素，并为纪念设计了世界上第一个核反应堆的著名美籍意大利物理学家恩里科-费米而命名。研究的第一种同位素是 255Fm，它是 238U 依次加入 15 个中子和 7 次β衰变的产物。 \n\n由于镄的重量尚未达到一定数量，因此除了用于研究目的外，几乎没有其他用途。它在生产更重的锕系元素方面的用途也很有限。 \n\n镄是用中子轰击较轻的锕系元素产生的。通过以 α-羟基异丁酸盐为洗脱剂的离子交换，将镄从其他类锕系元素中分离出来。 ',
    },
    Md: {
        name: '钔',
        translate_name: 'Mendelevium',
        atomic_mass: '258,0986',
        serial_number: '101',
        color: '#f7ad2c',
        text: '钔（Mendeleev）是为了纪念这位伟大的俄罗斯科学家而命名的，是锕系元素的一种典型金属；它的氧化度为 +3，并能形成典型的沉淀。钔的氧化度为+1 和+2，而假定的+4 氧化度的实际证明并不成功。目前已知的钔同位素有 16 种，其中最稳定的同位素的半衰期为 56 天。 \n\n第一批钔原子是在 1955 年合成的，当时同位素锿-254 的原子核受到了强加速氦原子核的辐照。 ',
    },
    No: {
        name: '锘',
        translate_name: 'Nobelium',
        atomic_mass: '259,1009',
        serial_number: '102',
        color: '#f7ad2c',
        text: '锘是锕系元素中的一种重传递态元素，为纪念炸药发明者、著名的诺贝尔奖创始人阿尔弗雷德-诺贝尔而命名。由于科学家可利用的锘原子通常不超过几百个，因此对其化学和物理特性的研究不多，但已发现它是镧系元素镱的类似物。它很容易转化为氧化度为 +2 的非典型锕系元素（和镧系元素）状态，其行为类似于碱土金属的阳离子：例如，在离子交换色谱法中，它的洗脱在钙和锶之间。虽然它也能形成氧化态为 +3 的化合物，在超强氧化剂的作用下还能形成氧化态为 +4 的化合物。 \n\n锘只能用于有限的研究目的。 \n\n为了产生研究最为深入的同位素 255No，需要用 12C 原子核轰击 248Cm 或 249Cf 靶件。用三万亿（3*1012）个碳-12 原子核辐照 350 µg 的锎-249，可产生约 1200 个锘-255 原子。由于锘能形成 No2+ 离子，因此很容易将其从其他锕系元素中分离出来。 ',
    },
    Lr: {
        name: '铹',
        translate_name: 'Lawrencium',
        atomic_mass: '266',
        serial_number: '103',
        color: '#f7ad2c',
        text: '铹是最后一种锕系元素，根据计算，它可能是一种具有还原性的固体。在水相中，铹通常以三电荷阳离子的形式存在，因此它显示出具有这种价态的锕系元素的典型特征：形成不溶性氢氧化物和氟化物。铹可以形成单羰基、二氢化物，甚至与有机硅化合物形成复杂的阴离子。 \n\n由于所有铹同位素的寿命都很短，这种元素没有实际应用价值。 \n\n产生铹-256 的方法之一是用氧-18轰击镅-243 核。 ',
    },
};